import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '@/services/API';
import { updateUser } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export const mutationUpdateUserTeam = async (
  teamId: string | null,
  userId: string
) => {
  const variables: UpdateUserMutationVariables = {
    input: {
      id: userId,
      teamID: teamId,
    },
  };
  const result = await callGraphQLApi<GraphQLResult<UpdateUserMutation>>(
    updateUser,
    variables
  );
  return result;
};
