import { PageLoading } from '@/ui/PageLoading';
import { Team } from '@/services/API';
import { useCallback } from 'react';
import { useAllTeamsList } from '@/features/teams/hooks/useAllTeamsList';
import { useAllTeamsSubscription } from '@/features/teams/hooks/useAllTeamsSubscription';
import { mutationDeleteTeam } from '@/features/teams/api/mutationDeleteTeam';
import AdminTeamListView from './AdminTeamListView';

export default function AdminTeamListClient() {
  const teamList = useAllTeamsList();
  useAllTeamsSubscription();

  const handleDeleteTeam = useCallback((elem: Team) => {
    mutationDeleteTeam(elem.id);
  }, []);

  if (!teamList.data) {
    return <PageLoading />;
  }
  return (
    <AdminTeamListView
      data={teamList.data}
      handleDeleteTeam={handleDeleteTeam}
    />
  );
}
