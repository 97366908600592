import createTanstackTableDataStore from '@/ui/TanstackTable/createTanstackTableDataStore';
import { UnloadedIPatientCase } from '@/utils/types/zod/patientCaseSchema';

const useCaseListTableStore =
  createTanstackTableDataStore<UnloadedIPatientCase>({
    storeName: 'admin-caselist-table-store',
    defaultColumnSizing: {},
    defaultColumnSorting: [
      {
        id: 'creationDate',
        desc: false,
      },
    ],
    defaultColumnVisibility: {
      // id: false,
    },
  });

export default useCaseListTableStore;
