import { ListTeamsQuery, ListTeamsQueryVariables, Team } from '@/services/API';
import { listTeams } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export const listTeamsQuery = async (variables: ListTeamsQueryVariables) => {
  const response = await callGraphQLApi<GraphQLResult<ListTeamsQuery>>(
    listTeams,
    variables
  );
  const items = response?.data?.listTeams?.items as Team[];
  return items;
};
