import { Team } from '@/services/API';
import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  useDisclosure,
  VStack,
  chakra,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { fuzzyFilter } from '@/utils/helpers/fuzzyFilter';
import Filters from '@/ui/TanstackTable/Filter/Filters';
import GlobalFilter from '@/ui/TanstackTable/Filter/GlobalFilter/GlobalFilter';
import ActiveFilters from '@/ui/TanstackTable/Filter/ActiveFilters/ActiveFilters';
import TanstackTableView from '@/ui/TanstackTable/TanstackTableView';
import Pagination from '@/ui/TanstackTable/Pagination';
import ColumnVisibility from '@/ui/TanstackTable/Columns/ColumnVisibility';
import { FilterType } from '@/ui/TanstackTable/Filter/NewFilterComponent';
import getColumns from './columns';
import useAdminTeamTableStore from './AdminTeamListTableStore';
import CreateTeamForm from '../CreateTeamForm/CreateTeamForm';

type Props = {
  data: Team[];
  handleDeleteTeam: (elem: Team) => void;
};

export default function AdminTeamListView({ data, handleDeleteTeam }: Props) {
  const { t } = useTranslation();

  const [updateTeam, setUpdateTeam] = useState<Team | null>(null);
  const {
    isOpen: isFormOpen,
    onOpen: openForm,
    onClose: closeForm,
  } = useDisclosure();

  const customCloseForm = useCallback(() => {
    setUpdateTeam(null);
    closeForm();
  }, [closeForm]);
  const customOpenForm = useCallback(
    (newDate: Team | null) => {
      setUpdateTeam(newDate);
      openForm();
    },
    [openForm]
  );

  const columns = getColumns({
    t,
    onEditTeamClick: customOpenForm,
    onDeleteTeamClick: handleDeleteTeam,
  });
  const [
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
  ] = useAdminTeamTableStore((state) => [
    state.columnFilters,
    state.setColumnFilters,
    state.columnVisibility,
    state.setColumnVisibility,
    state.columnSizing,
    state.setColumnSizing,
  ]);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const table = useReactTable({
    data: data,
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    state: {
      columnSizing,
      columnVisibility,
      columnFilters,
      globalFilter,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: (updater) => {
      const newVisibility =
        typeof updater === 'function' ? updater(columnVisibility) : updater;
      setColumnVisibility(newVisibility);
    },
    onColumnSizingChange: (updater) => {
      const newSizing =
        typeof updater === 'function' ? updater(columnSizing) : updater;
      setColumnSizing(newSizing);
    },
    defaultColumn: {},
    columnResizeMode: 'onEnd',
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: columns,
  });
  const filterTypeMappings: Partial<Record<keyof Team, FilterType>> = {
    createdAt: FilterType.DATE,
  };
  const possibleFilterOptions = {};

  return (
    <Box maxW="full" p={{ base: 3, md: 3 }}>
      <HStack justifyContent="space-between" my={2}>
        <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
          {t('admin.teams.list.title')}{' '}
        </chakra.h3>
        <Button
          background="blue.400"
          color="white"
          size="md"
          data-test="create-team-button-popUp"
          onClick={() => {
            customOpenForm(null);
          }}
        >
          {t('admin.teams.button.createNew')}
        </Button>

        <Modal isOpen={isFormOpen} onClose={customCloseForm}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box p="20px" gap="10px">
                <Heading size="sm" pb="5">
                  {t('admin.teams.form.title')}
                </Heading>

                <CreateTeamForm data={updateTeam} closeForm={customCloseForm} />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </HStack>
      <>
        <Flex gap="20px" justifyContent="center" alignItems="center" mb="4">
          <VStack w="full">
            <HStack w="full">
              <ColumnVisibility table={table} />
              <Filters
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                table={table}
                filterTypeMapping={filterTypeMappings}
                filterOptionsMapping={possibleFilterOptions}
              />
              <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                maxWidth="250px"
              />
              <ActiveFilters
                columnFilters={columnFilters}
                removeItemFunc={(elem) => {
                  setColumnFilters(
                    columnFilters.filter((f) => f.id !== elem.id)
                  );
                }}
                filterTypeMapping={filterTypeMappings}
              />
              <Spacer />
              <Pagination table={table} />
            </HStack>
          </VStack>
        </Flex>

        <TanstackTableView table={table} />
      </>
    </Box>
  );
}
