import { composeQuestionID } from '@/features/meeting/answer/answerHelper';
import { listAnswersByQuestionID } from '@/features/meeting/answer/listAnswersByQuestionId';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { TopRightType } from '@/utils/types/enums';
import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import { TopRightItemType } from '@/utils/types/zod/topRightObjectSchema';
import { Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import getAnswerItemsAccordingToUserAnswer from './utils/getAnswerItemsAccordingToUserAnswer';
import { NoUserAnswer } from '@/utils/errors/NoUserAnswer';
import { captureSentry } from '@/utils/helpers/sentryHelper';

type Props = {
  item: TopRightItemType;
  slides: Slide[];
};

function TopRightItem({ slides, item }: Props) {
  const { meeting, currentCaseId, isPreview } = useCurrentMeetingDataContext();
  const { userID } = useUserAuthenticationContext();

  const { isLoading, data } = useQuery({
    queryKey: ['getDisplayText', item],
    refetchOnWindowFocus: false,
    // enabled: allVariations.length > 0,
    queryFn: async () => {
      if (item.type === TopRightType.TEXT) {
        return item.title;
      }
      if (item.type === TopRightType.QUESTION_ANSWER) {
        //fetch the user's answer to that questionId
        if (!meeting || !currentCaseId || !userID) {
          //NOTE: It will be null if it is a preview
          if (!isPreview) {
            captureSentry({
              title:
                'TopRightItem - meeting or currentCaseId or userID is undefined',
              detail: {
                meeting,
                currentCaseId,
                userID,
              },
            });
          }
          return item.defaultTitle;
        }
        const questionID = composeQuestionID(
          meeting.id,
          currentCaseId,
          item.questionId
        );
        const allAnswers = (await listAnswersByQuestionID(questionID)) || [];
        //NOTE: If ever a user can send multiple answers, change this
        try {
          const answerItems = await getAnswerItemsAccordingToUserAnswer(
            userID,
            item.questionId,
            allAnswers,
            slides
          );
          return answerItems[0].title;
        } catch (e) {
          //This is an error I don't want to capture
          if (e instanceof NoUserAnswer) {
            return item.defaultTitle;
          }

          captureSentry({
            title: 'Error getting answer item according to user answer',
            error: e instanceof Error ? e : undefined,
            detail: { userID, questionID, allAnswers },
          });
          return item.defaultTitle;
        }
      }
      return 'N/A';
    },
  });
  return (
    <Flex
      background="livelinx.purple100"
      width="250px"
      height="80px"
      borderRadius="10px"
      alignItems="center"
      justifyContent="center"
      padding="20px"
      margin="10px"
    >
      <Text color="white" fontSize="20px" noOfLines={2} textAlign="center">
        {isLoading ? 'Loading...' : data}
      </Text>
    </Flex>
  );
}

export default TopRightItem;
