import {
  getMeetingStatus,
  getMeetingstatusColor,
  getMeetingStatusText,
} from '@/features/meeting/helpers/getMeetingStatus';
import { Meeting } from '@/services/API';
import { Tag } from '@chakra-ui/react';

type Props = {
  meeting: Meeting;
};

export const MeetingStatusTag = ({ meeting }: Props) => {
  const status = getMeetingStatus(meeting);
  return (
    <Tag ml={2} colorScheme={getMeetingstatusColor(status)}>
      {getMeetingStatusText(status)}
    </Tag>
  );
};
