import { ImageDisclaimer } from '@/utils/types/zod/disclaimerTypes/imageDisclaimerSchema';
import { Fade, Flex, Image } from '@chakra-ui/react';

type Props = {
  disclaimer: ImageDisclaimer;
  assetPath: string;
};

function ImageDisclaimer({ disclaimer, assetPath }: Props) {
  return (
    <Flex
      height="100%"
      justifyContent="center"
      alignItems="center"
      w="full"
      bgColor="black"
    >
      <Fade in>
        <Image
          objectFit="fill"
          key={disclaimer.url}
          alt="logo"
          src={`${assetPath + disclaimer.url}`}
        />
      </Fade>
    </Flex>
  );
}

export default ImageDisclaimer;
