import { z } from 'zod';
import { assetSchema } from './assetSchema';
import { caseSelectorItemOverrideSchema } from './caseSelectorItemOverrideSchema';
import { IDisclaimerSchema } from './disclaimerTypes/disclaimerSchema';
import { slideSchema } from './slideTypes/slideSchema';
import { variationSchema } from './variationSchema';

export const patientCaseSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  path: z.string(),
  slides: z.array(slideSchema),
  assets: z.array(assetSchema),
  variations: z.array(variationSchema),
  language: z.string(),

  //Optional
  name: z.string().optional(),
  disclaimer: IDisclaimerSchema.optional(),
  caseSelectorItemOverride: caseSelectorItemOverrideSchema.optional(),
});

export type PatientCase = z.infer<typeof patientCaseSchema>;

export const unloadedIPatientCaseSchema = z.object({
  id: z.string(),
  name: z.string(),
  path: z.string(),
  purchaseOrder: z.string(),
  creationDate: z.string(),
  clientName: z
    .string()
    .regex(/^[a-z-]+$/, "Must only contain lowercase letters and '-'")
    .refine((value) => !value.includes(' '), {
      message: 'Cannot contain spaces',
    }),
});

export type UnloadedIPatientCase = z.infer<typeof unloadedIPatientCaseSchema>;
