import { Divider, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { Fragment } from 'react';
import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import { PatientCase } from '@/utils/types/zod/patientCaseSchema';
import { getIndexFirstSlideNotAnamnese } from '../utils/getIndexFirstSlideNotAnamnese';
// import { getAnamneseItemFromCase } from '@/features/caseCluster/helpers/getAnamneseItem';
// import avatarManIcon from '@/assets/images/genderMaleIcon.svg';
// import avatarWomanIcon from '@/assets/images/genderFemaleIcon.svg';
import SupportedDocumentIcon from '@/assets/images/icons/supportingDocumentsIcon.svg';
import PatientProfileIcon from '@/assets/images/icons/patientProfileIcon.svg';
import { useTranslation } from 'react-i18next';

export type MeetingLeftSidebarProps = {
  patientCase: PatientCase;
  currentSlide: Slide;
  slideIndex: number;
  onPatientCaseClick: () => void;
  onSupportingDocsClick: () => void;
};

export default function MeetingLeftSidebar({
  patientCase,
  currentSlide,
  slideIndex,
  onPatientCaseClick,
  onSupportingDocsClick,
}: MeetingLeftSidebarProps) {
  const { t } = useTranslation();
  // const anamneseItem = getAnamneseItemFromCase(patientCase);
  const elements = [
    {
      conditionToShow:
        !currentSlide.hidePatientProfile &&
        slideIndex >= getIndexFirstSlideNotAnamnese(patientCase),
      toolTip: 'Patient case',
      icon: (
        <Flex position="relative">
          <PatientProfileIcon style={{ fontSize: '60px' }} />
          {/* <Icon
            as={
              anamneseItem?.gender == 'woman' ? avatarWomanIcon : avatarManIcon
            }
            boxSize="30px"
          /> */}
        </Flex>
      ),
      onClick: onPatientCaseClick,
      dataTest: 'left-sidebar-patientcase',
    },
    {
      conditionToShow:
        !currentSlide.hideSupportedDocuments &&
        currentSlide.libraryRefs &&
        currentSlide.libraryRefs.length > 0,
      toolTip: t('home.caseCluster.slide.supportingDocuments'),
      icon: (
        <Flex position="relative">
          <SupportedDocumentIcon style={{ fontSize: '60px' }} />
          {currentSlide.libraryRefs?.length > 0 && (
            <Flex
              position="absolute"
              top={2}
              right={2}
              bg="red"
              borderRadius="50%"
              boxSize={4}
              justifyContent="center"
              alignItems="center"
              color="white"
              fontSize="10px"
              zIndex={5}
            >
              {currentSlide.libraryRefs.length}
            </Flex>
          )}
        </Flex>
      ),
      onClick: onSupportingDocsClick,
      dataTest: 'left-sidebar-library',
    },
  ];

  // filter elements to dislay only
  const displayElements = elements.filter((elem) => elem.conditionToShow);
  // if no elements to display, just do not render this component
  if (!displayElements.length) return null;

  return (
    <Flex
      position="absolute"
      width={16}
      // left={4}
      bg="white"
      height="fit-content"
      flexDir="column"
      top="50%"
      borderRightRadius="10px"
      transform="translateY(-50%)"
      boxShadow="white"
      overflow="hidden"
      shadow="sidebar"
    >
      {displayElements.map((elem, idx) => {
        return (
          <Fragment key={elem.toolTip}>
            <Tooltip label={elem.toolTip} isDisabled={true}>
              <IconButton
                data-test={elem.dataTest}
                boxSize={16}
                onClick={elem.onClick}
                aria-label={elem.toolTip}
                icon={elem.icon}
                bg="transparent"
                _hover={{ bg: 'transparent' }}
              />
            </Tooltip>
            {idx !== elements.length - 1 && (
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Divider width="80%" />
              </Flex>
            )}
          </Fragment>
        );
      })}
    </Flex>
  );
}
