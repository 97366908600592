import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  Select,
  HStack,
  VStack,
} from '@chakra-ui/react';
import ItemList from '@/ui/ItemList/ItemList';
import { useCaseListQuery } from '@/features/caseCluster/hooks/useCaseListQuery';
import { isQueryFinishedLoading } from '@/utils/isQueryFinishedLoading';
import { AppSpinner } from '@/ui/AppSpinner';
import { mutationCreateCaseCluster } from '@/features/caseCluster/api/mutationCreateCaseCluster';
import { useAllClientListQuery } from '@/features/clients/hooks/useAllClientListQuery';
import { CaseCluster } from '@/services/API';
import { mutationUpdateCaseCluster } from '@/features/caseCluster/api/mutationUpdateCaseCluster';
import { assertIsTruthy } from '@/utils/types/type-assertion/generic/assertIsTruthy';
import { useEffect } from 'react';

type Props = {
  data: CaseCluster | null;
  closeForm: () => void;
};

type FormData = {
  name: string;
  clientIDs: string[];
  selectedClientID: string | null;
  caseIDs: string[];
  selectedCaseID: string | null;
};

const CreateCaseClusterForm = ({ data, closeForm }: Props) => {
  const isEdit = !!data;
  const caseList = useCaseListQuery();
  const clientList = useAllClientListQuery();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({});

  const currentClientIDs = watch('clientIDs') ?? [];
  const currentCaseIDs = watch('caseIDs') ?? [];

  // --------------------- handlers ------------------------

  const handleAddCase = () => {
    const selectedId = getValues('selectedCaseID');
    if (selectedId && !currentCaseIDs.includes(selectedId)) {
      setValue('caseIDs', [...currentCaseIDs, selectedId]);
      setValue('selectedCaseID', null);
    }
  };

  const removeCase = (displayName: string) => {
    const elemsToRemove = caseList.data?.filter(
      (elem) => elem.name === displayName
    );
    setValue(
      'caseIDs',
      currentCaseIDs.filter(
        (id) => !elemsToRemove?.find((elem) => elem.id === id)
      )
    );
  };

  const handleAddClient = () => {
    const selectedId = getValues('selectedClientID');
    if (selectedId && !currentClientIDs.includes(selectedId)) {
      setValue('clientIDs', [...currentClientIDs, selectedId]);
      setValue('selectedClientID', null);
    }
  };

  const removeClient = (displayName: string) => {
    const elemsToRemove = clientList.data?.filter(
      (elem) => elem.clientName === displayName
    );
    setValue(
      'clientIDs',
      currentClientIDs.filter(
        (id) => !elemsToRemove?.find((elem) => elem.id === id)
      )
    );
  };

  const getAvailableCases = () =>
    caseList.data?.filter((elem) => !currentCaseIDs.includes(elem.id)) || [];

  const getAvailableClients = () =>
    clientList.data?.filter((elem) => !currentClientIDs.includes(elem.id)) ||
    [];

  const onSubmit = async (values: FormData) => {
    if (isEdit) {
      assertIsTruthy(data);
      await mutationUpdateCaseCluster({
        input: {
          id: data.id,
          name: values.name,
          clientIDs: values.clientIDs,
        },
      });
    } else {
      await mutationCreateCaseCluster({
        input: {
          name: values.name,
          cases: values.caseIDs,
          clientIDs: values.clientIDs,
        },
      });
    }
    closeForm();
    reset();
  };

  // --------------------- effects ------------------------

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        clientIDs: data.clientIDs || [],
        caseIDs: data.cases || [],
      });
    }
  }, [data]);

  if (
    !isQueryFinishedLoading(caseList) ||
    !isQueryFinishedLoading(clientList)
  ) {
    return <AppSpinner />;
  }

  // --------------------- render ------------------------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack gap="10px" pb="10px">
        {/* NAME */}
        <FormControl isRequired isInvalid={Boolean(errors.name)}>
          <FormLabel fontSize="sm" fontWeight="normal" mb="1">
            {t('admin.caseClusters.createForm.field.name')}:
          </FormLabel>
          <Input
            type="text"
            {...register('name', { required: true })}
            placeholder="Name"
          />
          {errors.name && (
            <FormHelperText color="red">
              {t('admin.caseClusters.createForm.name.error')}
            </FormHelperText>
          )}
        </FormControl>

        {/* CASE LIST */}

        <FormControl>
          <FormLabel fontSize="sm" fontWeight="normal" mb="1">
            {t('admin.caseClusters.createForm.field.cases')}
          </FormLabel>

          <HStack>
            <Controller
              name="selectedCaseID"
              control={control}
              disabled={isEdit}
              render={({ field }) => (
                <Select
                  placeholder={
                    t('dmin.caseClusters.createForm.field.cases.placeholder') ||
                    'Select'
                  }
                  {...field}
                  value={field.value ?? undefined}
                >
                  {getAvailableCases().map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.name}
                    </option>
                  ))}
                </Select>
              )}
            />

            <Button
              background="blue.400"
              color="white"
              size="md"
              onClick={handleAddCase}
              isDisabled={isEdit}
            >
              {t('common.add')}
            </Button>
          </HStack>

          <VStack p={2} align="flex-start">
            {currentCaseIDs.length && (
              <ItemList
                itemList={currentCaseIDs.map((caseId) => {
                  const elem = caseList.data?.find(
                    (elem) => elem.id === caseId
                  );
                  return elem ? elem.name : 'N/A';
                })}
                removeItemFunc={removeCase}
                disableAllRemove={isEdit}
              />
            )}
          </VStack>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="sm" fontWeight="normal" mb="1">
            {t('admin.caseClusters.createForm.field.clients')}
          </FormLabel>

          <HStack>
            <Controller
              name="selectedClientID"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder={
                    t(
                      'dmin.caseClusters.createForm.field.clients.placeholder'
                    ) || 'Select'
                  }
                  {...field}
                  value={field.value ?? undefined}
                >
                  {getAvailableClients().map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.clientName}
                    </option>
                  ))}
                </Select>
              )}
            />

            <Button
              background="blue.400"
              color="white"
              size="md"
              onClick={handleAddClient}
            >
              {t('common.add')}
            </Button>
          </HStack>

          <VStack p={2} align="flex-start">
            {currentClientIDs.length && (
              <ItemList
                itemList={currentClientIDs.map((clientId) => {
                  const elem = clientList.data?.find(
                    (elem) => elem.id === clientId
                  );
                  return elem ? elem.clientName : 'N/A';
                })}
                removeItemFunc={removeClient}
              />
            )}
          </VStack>
        </FormControl>
      </VStack>

      {/* SUBMIT */}
      <Button
        isDisabled={currentCaseIDs.length < 1 || currentClientIDs.length < 1}
        onClick={handleSubmit(onSubmit)}
        p="2"
        mx="2"
        w="20%"
        background="blue.400"
        color="white"
        variant="solid"
      >
        {isEdit ? t('common.update') : t('common.create')}
      </Button>
    </form>
  );
};

export default CreateCaseClusterForm;
