import { useTranslation } from 'react-i18next';
import {
  CaseCluster,
  CreateMeetingInput,
  Meeting,
  UpdateMeetingInput,
  User,
} from '@/services/API';
import { Controller, useForm } from 'react-hook-form';
import { updateAMeeting } from '@/store/thunk/meeting';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  Select,
  VStack,
  Spacer,
  Checkbox,
  Text,
  HStack,
  Button as ChakraButton,
  Switch,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useAllClientList from '@/features/clients/hooks/useAllClientList';
import ItemList from '@/ui/ItemList/ItemList';
import { getClientNameFromID } from '@/utils/helpers/getClientNameFromID';
import { MEETING_DEFAULT_LOCATION } from '@/features/meeting/helpers/constants';
import { MeetingTypes } from '@/features/caseCluster/caseCluster.types';
import { IsLocalHost } from '@/utils/helpers/isLocalHost';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import {
  DEFAULT_HOST_NAME,
  DEFAULT_HOST_VALUE,
} from '@/utils/constants/app.constants';
import useMeetingUserAccessForMeeting from '@/services/hooks/useMeetingUserAccessForMeeting';
import { pureCreateMeeting } from '@/backend/pureCreateMeeting';
import { useAllTeamsList } from '@/features/teams/hooks/useAllTeamsList';
import { isQueryFinishedLoading } from '@/utils/isQueryFinishedLoading';
import { AppSpinner } from '@/ui/AppSpinner';
import { isTruthy } from '@/utils/types/type-validation/generic/isTruthy';

type Props = {
  user: User;
  userList: User[];
  caseClusterlist: CaseCluster[];
  meeting?: Meeting; // if we have a meeting, we are in edition mode or in duplicate mode
  isDuplicateMeeting?: boolean;
  closeMeetingForm: () => void;
};

type FormData = {
  name: string;
  caseClusterID: string;
  date: string;
  host: string;
  hostFullName: string;
  //This is just so that the form would accept this input, but it is not used, the useState is used
  meetingClients: string[];
  meetingTeams: string[] | null;
  selectedTeam: string | null;
  generateGuestUrl: boolean;
  generatePreviewCode: boolean;
  isDemo: boolean;
  location: string;
  customNotes: string;
  purchaseOrderNbr: string;
  meetingType: string;
  streamUrl: string;
};

const generateCode = () => {
  //random string of 6 characters
  return Math.random().toString(36).substring(2, 8);
};

const CreateMeetingForm = ({
  userList,
  caseClusterlist,
  meeting,
  isDuplicateMeeting,
  closeMeetingForm,
}: Props) => {
  const { t } = useTranslation();
  const { user } = useUserAuthenticationContext();
  const allClients = useAllClientList();
  const allTeams = useAllTeamsList();
  const { userAccessList } = useMeetingUserAccessForMeeting(meeting?.id);
  const filteredUserList = useMemo(() => {
    return userList.filter((user) => !user.isTemporary && !user.isMock);
  }, [userList]);
  const toast = useToast();

  //list of the ids of the clients that are part of the meeting
  const [clientsAddedList, setClientsAddedList] = useState<string[]>(
    //Note: This function maps the clientIDs to clientNames and check for undefined values
    meeting?.clientIDs?.map((elem) => {
      const name = allClients
        ? getClientNameFromID(allClients, elem)
        : undefined;
      return name ? name : elem;
    }) || []
  );
  const [selectedClient, setSelectedClient] = useState<string | undefined>(
    undefined
  );
  const [eventDate, setEventDate] = useState<Date>(
    meeting ? new Date(meeting.eventDate) : new Date()
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    getValues,
    watch,
    setValue,
    control,
  } = useForm<FormData>();

  const meetingTypeWatch = watch('meetingType');
  const locationWatch = watch('location');
  const currentMeetingTeams = watch('meetingTeams') ?? [];
  // const selectedTeam = watch('selectedTeam');

  // --------------------- handlers ------------------------

  const onSubmit = useCallback(
    async (values: FormData) => {
      if (!allClients) {
        throw new Error('allClients is undefined, this should not happen');
      }
      if (
        values.meetingType === MeetingTypes.LIVE &&
        clientsAddedList.length === 0
      ) {
        toast({
          title: 'Error',
          description: t('admin.createMeeting.form.meetingClients.live.error'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      if (
        values.meetingType === MeetingTypes.LIVE &&
        values.meetingTeams?.length === 0
      ) {
        toast({
          title: 'Error',
          description: t('admin.createMeeting.form.meetingTeams.live.error'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      const clientsAddedListIDS: string[] = [];

      clientsAddedList.forEach((clientName) => {
        const found = allClients.find((elem) => elem.clientName === clientName);
        if (found) {
          clientsAddedListIDS.push(found.id);
        }
      });

      if (!meeting || isDuplicateMeeting) {
        const meetingInput: CreateMeetingInput = {
          name: values.name,
          caseClusterID: values.caseClusterID,
          hostID: values.host,
          hostFullName: values.hostFullName,
          eventDate: eventDate.toISOString(),
          eventLocation: values.location,
          clientIDs: clientsAddedListIDS,
          purchaseOrderNbr: values.purchaseOrderNbr,
          meetingNotes: values.customNotes,
          currentSlide: 0,
          isMock: false,
          isInTesting: false,
          isArchived: false,
          meetingType: values.meetingType,
          isDemo: values.isDemo,
          streamUrl: values.streamUrl,
          teamIDs: values.meetingTeams,
        };
        if (values.generateGuestUrl) meetingInput.guestUrlCode = generateCode();
        if (values.generatePreviewCode)
          meetingInput.previewCode = generateCode();
        await pureCreateMeeting(
          meetingInput,
          isDuplicateMeeting
            ? userAccessList.map((val) => val.userID)
            : undefined
        ).finally(() => {
          closeMeetingForm();
        });
      } else {
        const meetingUpdate: UpdateMeetingInput = {
          id: meeting.id,
          name: values.name,
          caseClusterID: values.caseClusterID,
          hostID: values.host,
          hostFullName: values.hostFullName,
          eventDate: eventDate.toISOString(),
          eventLocation: values.location,
          clientIDs: clientsAddedListIDS,
          purchaseOrderNbr: values.purchaseOrderNbr,
          meetingNotes: values.customNotes,
          meetingType: values.meetingType,
          streamUrl: values.streamUrl,
          teamIDs: values.meetingTeams,
        };
        if (values.generateGuestUrl) {
          meetingUpdate.guestUrlCode = generateCode();
        }
        if (values.generatePreviewCode) {
          meetingUpdate.previewCode = generateCode();
        }
        await updateAMeeting(meetingUpdate).finally(() => {
          closeMeetingForm();
        });
      }
    },
    [
      allClients,
      clientsAddedList,
      closeMeetingForm,
      eventDate,
      t,
      meeting,
      isDuplicateMeeting,
      userAccessList,
    ]
  );

  const removeClient = (client: string) => {
    setClientsAddedList((prev) => prev.filter((item) => item !== client));
  };

  const addClient = () => {
    if (selectedClient) {
      setClientsAddedList((prev) => [...prev, selectedClient]);
    }
  };

  const handleAddTeam = () => {
    const teamId = getValues('selectedTeam');
    if (teamId && !currentMeetingTeams.includes(teamId)) {
      setValue('meetingTeams', [...currentMeetingTeams, teamId]);
      setValue('selectedTeam', null); // Reset the select box
    }
  };

  const removeTeam = (teamName: string) => {
    const teamsToRemove = allTeams.data?.filter(
      (elem) => elem.teamName === teamName
    );
    setValue(
      'meetingTeams',
      currentMeetingTeams.filter(
        (id) => !teamsToRemove?.find((elem) => elem.id === id)
      )
    );
  };

  const getAvailableTeams = () =>
    allTeams.data?.filter((team) => !currentMeetingTeams.includes(team.id)) ||
    [];

  // --------------------- effects ------------------------

  useEffect(() => {
    //reset  fields when component unmounts
    return () => reset();
  }, [closeMeetingForm]);

  useEffect(() => {
    const defaultValues: { host?: string; meetingTeams?: string[] } = {};
    if (meeting) {
      defaultValues.host = meeting.hostID;
      defaultValues.meetingTeams = meeting.teamIDs?.filter(isTruthy) ?? [];
      reset({ ...defaultValues });
    } else reset({ ...defaultValues });
  }, [meeting]);

  // NOTE: this is needed for the second time we open the form for some reason... It's a hack
  useEffect(() => {
    if (getValues('meetingType') === undefined) {
      setValue('meetingType', meeting?.meetingType ?? MeetingTypes.LIVE);
    }
  }, [meetingTypeWatch]);

  useEffect(() => {
    if (getValues('meetingType') === MeetingTypes.LIVE) {
      const clients = clientsAddedList.join(', ');
      const location = getValues('location');
      const date = eventDate.toISOString().slice(0, 10).replace(/-/g, '');
      setValue(
        'name',
        `${[clients, location, date].filter((elem) => !!elem).join(' - ')}`
      );
    } else if (!meeting) {
      setValue('name', '');
    }
  }, [meetingTypeWatch, clientsAddedList, locationWatch, eventDate]);

  if (isQueryFinishedLoading(allTeams) === false) {
    return <AppSpinner />;
  }

  // --------------------- render ------------------------

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isSubmitting}>
          <VStack gap="10px" pb="10px">
            <FormControl
              isRequired
              isInvalid={!meeting && Boolean(errors.meetingType)}
            >
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.meetingType.label')}
              </FormLabel>
              <Select
                data-test="meeting-meetingType-dropdDown"
                {...register('meetingType', { required: true })}
                defaultValue={meeting ? meeting.meetingType : MeetingTypes.LIVE}
              >
                {Object.values(MeetingTypes).map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>

              {errors.meetingType && (
                <FormHelperText color="red">
                  {t('admin.createMeeting.form.meetingType.error')}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.meetingClients.label')}
              </FormLabel>

              <HStack>
                <Select
                  {...register('meetingClients', { required: false })}
                  placeholder={
                    t('admin.createMeeting.form.meetingClients.placeholder') ||
                    'Click to add a client'
                  }
                  value={selectedClient}
                  onChange={(e) => setSelectedClient(e.target.value)}
                >
                  {allClients &&
                    allClients.map((client) => {
                      return clientsAddedList.includes(
                        client.clientName
                      ) ? null : (
                        <option key={client.id} value={client.clientName}>
                          {client.clientName}
                        </option>
                      );
                    })}
                </Select>

                <ChakraButton
                  background="blue.400"
                  color="white"
                  size="md"
                  onClick={() => addClient()}
                >
                  {t('common.add')}
                </ChakraButton>
              </HStack>

              <VStack p={2} align="flex-start">
                {clientsAddedList && (
                  <ItemList
                    itemList={clientsAddedList}
                    removeItemFunc={removeClient}
                  />
                )}
                {clientsAddedList.length === 0 && (
                  <Text>
                    {t('admin.createMeeting.form.meetingClients.empty')}
                  </Text>
                )}
              </VStack>
            </FormControl>

            {/*   Meeting teams */}
            <FormControl>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.meetingTeams.label')}
              </FormLabel>

              <HStack>
                <Controller
                  name="selectedTeam"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={
                        t(
                          'admin.createMeeting.form.meetingTeams.placeholder'
                        ) || 'Click to add a team'
                      }
                      {...field}
                      value={field.value ?? undefined}
                    >
                      {getAvailableTeams().map((team) => (
                        <option key={team.id} value={team.id}>
                          {team.teamName}
                        </option>
                      ))}
                    </Select>
                  )}
                />

                <ChakraButton
                  background="blue.400"
                  color="white"
                  size="md"
                  onClick={handleAddTeam}
                >
                  {t('common.add')}
                </ChakraButton>
              </HStack>

              <VStack p={2} align="flex-start">
                {currentMeetingTeams.length && (
                  <ItemList
                    itemList={currentMeetingTeams.map((teamId) => {
                      const team = allTeams.data?.find(
                        (elem) => elem.id === teamId
                      );
                      return team ? team.teamName : 'N/A';
                    })}
                    removeItemFunc={removeTeam}
                  />
                )}
                {currentMeetingTeams.length === 0 && (
                  <Text>
                    {t('admin.createMeeting.form.meetingTeams.empty')}
                  </Text>
                )}
              </VStack>
            </FormControl>

            <FormControl isRequired isInvalid={Boolean(errors.name)}>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.meetingName.label')}
              </FormLabel>
              <Input
                type="text"
                {...register('name', { required: true })}
                placeholder="Name"
                defaultValue={
                  (meeting && meeting.name) ||
                  (IsLocalHost() ? `Meeting - ${new Date().toISOString()}` : '')
                }
                isDisabled={getValues('meetingType') === MeetingTypes.LIVE}
              />
              {errors.name && (
                <FormHelperText color="red">
                  {t('admin.createMeeting.form.meetingName.error')}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!meeting && Boolean(errors.caseClusterID)}
            >
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.caseCluster.label')}
              </FormLabel>
              <Select
                data-test="meeting-caseCluster-dropdDown"
                {...register('caseClusterID', {
                  required: !(meeting && !isDuplicateMeeting),
                })}
                defaultValue={meeting && meeting.caseClusterID}
                isDisabled={Boolean(meeting && !isDuplicateMeeting)}
              >
                {caseClusterlist &&
                  caseClusterlist.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Select>

              {errors.caseClusterID && (
                <FormHelperText color="red">
                  {t('admin.createMeeting.form.meetingCaseCluster.error')}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={Boolean(errors.host)}>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.host.label')}
              </FormLabel>
              <Select
                data-test="meeting-host-dropdDown"
                {...register('host', {
                  required: true,
                  validate: (value) => {
                    if (value === DEFAULT_HOST_VALUE) return false;
                    return true;
                  },
                })}
                defaultValue={
                  IsLocalHost()
                    ? user?.id || DEFAULT_HOST_VALUE
                    : DEFAULT_HOST_VALUE
                }
              >
                <option key={DEFAULT_HOST_VALUE} value={DEFAULT_HOST_VALUE}>
                  {t('admin.createMeeting.form.host.placeholder')}
                </option>
                {filteredUserList &&
                  filteredUserList
                    .sort((a, b) => (a.email < b.email ? -1 : 1)) // sort ascending
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.email}
                      </option>
                    ))}
              </Select>

              {errors.host && (
                <FormHelperText color="red">
                  {t('admin.createMeeting.form.meetingHost.error')}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={Boolean(errors.hostFullName)}>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.hostFullName.label')}
              </FormLabel>
              <Input
                type="text"
                {...register('hostFullName', { required: true })}
                placeholder="John Doe"
                defaultValue={
                  meeting?.hostFullName ||
                  (IsLocalHost() ? DEFAULT_HOST_NAME : '')
                }
              />
              {errors.hostFullName && (
                <FormHelperText color="red">
                  {t('admin.createMeeting.form.hostFullName.error')}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={Boolean(errors.location)}>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.meetingLocation.label')}
              </FormLabel>
              <Input
                type="text"
                {...register('location', { required: true })}
                placeholder="Location"
                defaultValue={
                  meeting?.eventLocation || MEETING_DEFAULT_LOCATION
                }
              />
              {errors.location && (
                <FormHelperText color="red">
                  {t('admin.createMeeting.form.meetingLocation.error')}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={Boolean(errors.date)}>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.eventDate.label')}
              </FormLabel>
              <div
                style={{
                  border: '1px solid #E2E8F0',
                  borderRadius: '5px',
                  paddingTop: '7px',
                  paddingBottom: '7px',
                  paddingLeft: '15px',
                  opacity: isSubmitting ? 0.5 : 1,
                }}
              >
                <DatePicker
                  selected={eventDate} // Use your state value here
                  onChange={(date: Date) => setEventDate(date)} // Update the state when the date changes
                  dateFormat="yyyy-MM-dd" // Customize the date format as needed
                />
              </div>
              {errors.date && (
                <FormHelperText color="red">
                  {t('admin.createMeeting.form.eventDate.error')}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              isRequired
              isInvalid={Boolean(errors.purchaseOrderNbr)}
            >
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.purchaseOrderNbr.label')}
              </FormLabel>
              <Input
                type="text"
                {...register('purchaseOrderNbr', { required: true })}
                defaultValue={
                  (meeting && meeting.purchaseOrderNbr) ||
                  (IsLocalHost() ? 'PO-111' : '')
                }
              />
              {errors.purchaseOrderNbr && (
                <FormHelperText color="red">
                  {t('admin.createMeeting.form.purchaseOrderNbr.error')}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isInvalid={Boolean(errors.customNotes)}>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.customNotes.label')}
              </FormLabel>
              <Input
                type="text"
                {...register('customNotes')}
                defaultValue={(meeting && meeting.meetingNotes) || ''}
              />
              {errors.customNotes && (
                <FormHelperText color="red">
                  {t('admin.createMeeting.form.customNotes.error')}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isInvalid={Boolean(errors.streamUrl)}>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.streamUrl.label')}
              </FormLabel>
              <Input
                type="text"
                {...register('streamUrl')}
                defaultValue={(meeting && meeting.streamUrl) || ''}
              />
              {errors.streamUrl && (
                <FormHelperText color="red">
                  {t('admin.createMeeting.form.streamUrl.error')}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.demo.label')}
              </FormLabel>
              <Switch
                {...register('isDemo', { required: false })}
                disabled={!!meeting && !isDuplicateMeeting}
                defaultChecked={meeting?.isDemo || false}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  // justifyContent: 'center',
                }}
              >
                <Text opacity={!!meeting && !isDuplicateMeeting ? 0.6 : 1}>
                  {t('admin.createMeeting.form.demo.text')}
                </Text>
              </Switch>
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.guestUrl.label')}
              </FormLabel>
              <Checkbox {...register('generateGuestUrl', { required: false })}>
                {t('admin.createMeeting.form.guestUrl.text')}
              </Checkbox>
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                {t('admin.createMeeting.form.previewCode.label')}
              </FormLabel>
              <Checkbox
                {...register('generatePreviewCode', { required: false })}
              >
                {t('admin.createMeeting.form.previewCode.text')}
              </Checkbox>
            </FormControl>

            <Spacer h="5px" />

            <Button
              // onClick={handleSubmit(onSubmit)}
              type="submit"
              p="2"
              width="full"
              background="blue.400"
              color="white"
              variant="solid"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {meeting
                ? isDuplicateMeeting
                  ? t('common.duplicate')
                  : t('common.update')
                : t('common.create')}
            </Button>
          </VStack>
        </fieldset>
      </form>
    </>
  );
};

export default CreateMeetingForm;
