import { Tag, Text, Tooltip } from '@chakra-ui/react';

type Props = {
  client: string;
};

export default function ClientTag({ client }: Props) {
  return (
    <Tag ml={2} colorScheme="blue" userSelect="none">
      <Tooltip label={client}>
        <Text textOverflow="ellipsis" noOfLines={1}>
          {client}
        </Text>
      </Tooltip>
    </Tag>
  );
}
