import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';

type Props = {
  itemList: string[];
  removeItemFunc: (item: string) => void;
  disableAllRemove?: boolean;
};

/*
Used in:
  - Admin Panel -> CaseCluster -> Create
  - Admin Panel -> Meeting -> Create
*/
export default function ItemList({
  itemList,
  removeItemFunc,
  disableAllRemove,
}: Props) {
  return (
    <>
      {itemList.map((item) => (
        <Tag key={item} size="sm" bgColor="primary" color="white">
          <TagLabel>{item}</TagLabel>
          <TagCloseButton
            isDisabled={disableAllRemove}
            onClick={() => removeItemFunc(item)}
          />
        </Tag>
      ))}
    </>
  );
}
