/**
import React from 'react';
import { useModal } from './ModalContext';
import { Button } from '@chakra-ui/react';

const SomeComponent = () => {
    const { openModal } = useModal();

    const handleOpenModal = () => {
        openModal({
            title: 'My Modal Title',
            body: 'This is the body of the modal.',
        });
    };

    return (
        <Button onClick={handleOpenModal}>Open Modal</Button>
    );
};

export default SomeComponent; 
 */

import { createContext, useContext, useState } from 'react';
import { CustomModal, ModalProps } from './CustomModal';

type Props = {
  children: React.ReactNode;
};

type ContextType = {
  openModal: (modalProps: ModalProps) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ContextType>({
  openModal: () => {},
  closeModal: () => {},
});

export const StandaloneModalProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalQueue, setModalQueue] = useState<ModalProps[]>([]);
  const [currentModal, setCurrentModal] = useState<ModalProps | null>(null);

  const openModal = (modalProps: ModalProps) => {
    if (isOpen) {
      setModalQueue((prev) => [...prev, modalProps]);
    } else {
      setIsOpen(true);
      setCurrentModal(modalProps);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    if (modalQueue.length > 0) {
      const nextModal = modalQueue[0];
      setModalQueue((prev) => prev.slice(1));
      setCurrentModal(nextModal);
      setIsOpen(true);
    }
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <CustomModal
        isOpen={isOpen}
        onClose={closeModal}
        modalProps={currentModal}
      />
    </ModalContext.Provider>
  );
};

export const useStandaloneModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error(
      'ModalContext must be used within a StandaloneModalProvider'
    );
  }
  return context;
};
