import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { CaseCluster, Client, Meeting } from '@/services/API';
import dateInRangeFilterFn from '@/ui/TanstackTable/Filter/date/dateInRangeFilterFn';
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { parseDate } from '@/utils/helpers/parseDate';
import { DeleteOutlined } from '@ant-design/icons';
import {
  createArrayFilterFn,
  stringArrayFilterFn,
} from '@/ui/TanstackTable/Filter/array/arrayFilterFns';
import ClientTag from '@/components/ClientTag/ClientTag';
import CaseClusterTag from '@/components/CaseClusterTag/CaseClusterTag';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { FiEdit } from 'react-icons/fi';
import { useConfirmModal } from '@/ui/ConfirmModal/ConfirmModal';

export type AdminCaseClusterListItemType = CaseCluster & {
  linkedMeetings: Meeting[];
  canBeDeleted: boolean;
  clients: Client[];
  clientText: string;
};

const columnHelper = createColumnHelper<AdminCaseClusterListItemType>();

type Args = {
  t: TFunction<'translation', undefined, 'translation'>;
  onEditClick: (data: CaseCluster) => void;
  onDeleteClick: (caseClusterID: string) => void;
  confirmModalUtils: ReturnType<typeof useConfirmModal>;
};

const getColumns = ({
  t,
  onDeleteClick,
  onEditClick,
  confirmModalUtils,
}: Args) => {
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: (props) => (
        <Tooltip label={props.getValue()}>
          <Text>{props.getValue()}</Text>
        </Tooltip>
      ),
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      filterFn: dateInRangeFilterFn,
      cell: (props) => <Text>{parseDate(props.getValue() || '')}</Text>,
    }),
    columnHelper.accessor('cases', {
      header: 'Cases Included',
      filterFn: stringArrayFilterFn,
      cell: (props) => {
        const cases = props.getValue();
        return (
          <Flex flexDir="row" gap={2} flexWrap="wrap">
            {cases.map((elem) => (
              <CaseClusterTag key={elem} name={elem} />
            ))}
          </Flex>
        );
      },
    }),
    columnHelper.accessor('linkedMeetings', {
      header: 'Linked Meetings',
      filterFn: createArrayFilterFn<Meeting>('name'),
      cell: (props) => {
        const elems = props.getValue();
        return (
          <Flex flexDir="row" gap={2} flexWrap="wrap">
            {elems.map((elem) => (
              <Tooltip
                key={elem.id}
                label={
                  <div>
                    {elem.eventDate && (
                      <p>
                        <h2>{parseDate(elem.eventDate)}</h2>
                      </p>
                    )}
                    {elem.name}
                  </div>
                }
              >
                <Tag ml={2} colorScheme="blue" w="min">
                  <Text textOverflow="ellipsis" noOfLines={1}>
                    {elem.name}
                  </Text>
                </Tag>
              </Tooltip>
            ))}
          </Flex>
        );
      },
    }),
    columnHelper.accessor('clientText', {
      id: 'clients',
      header: 'Client',
      // filterFn: createArrayFilterFn<Client>('clientName'),
      cell: (props) => {
        const clients = props.row.original.clients;
        return (
          <Flex flexDir="row" gap={2} flexWrap="wrap">
            {clients.map((elem) => (
              <ClientTag key={elem.id} client={elem.clientName} />
            ))}
          </Flex>
        );
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      size: 85,
      cell: (props) => {
        const data = props.row.original;
        return (
          <>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiOutlineDotsVertical />}
                variant="outline"
                size="xs"
                border="none"
              />
              <MenuList>
                <MenuItem
                  icon={<FiEdit />}
                  onClick={() => {
                    onEditClick(data);
                  }}
                >
                  {t('common.edit')}
                </MenuItem>
                <MenuItem
                  icon={<DeleteOutlined />}
                  isDisabled={!data.canBeDeleted}
                  onClick={() => {
                    confirmModalUtils.confirm({
                      title: (
                        <div>
                          {t('admin.caseClusters.delete.confirmText')}
                          <br />
                          {t('meeting.header.confirmRestart')}
                        </div>
                      ),
                      onConfirm: () => onDeleteClick(data.id),
                    });
                  }}
                >
                  {t('common.delete')}
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        );
      },
    }),
  ];

  return columns;
};

export default getColumns;
