import {
  Box,
  HStack,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Flex,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import * as data from './LorealSkinExpertSlideData.json';
import productsThemeA from './productsThemeA.json';
import productsThemeB from './productsThemeB.json';
import productsThemeC from './productsThemeC.json';
import productsThemeD from './productsThemeD.json';
import productsThemeE from './productsThemeE.json';
import productsThemeF from './productsThemeF.json';
import { PlusSquareIcon } from '@chakra-ui/icons';
import { CustomSlideType } from '@/utils/types/zod/slideTypes/customSlideSchema';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { useQuestionContext } from '@/components/CaseSlide/QuestionSlide/context/QuestionContext';
import isSlideItemTextAnswerItem from '@/utils/types/type-validation/isSlideItemTextAnswerItem';
import usePreviousQuestionSlideUserAnswer from '@/customSlides/hooks/usePreviousQuestionSlideUserAnswer';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { AppSpinner } from '@/ui/AppSpinner';

type Props = {
  slide: CustomSlideType;
};

// type Category = {
//   id: string;
//   name: string;
// };

type Product = {
  id: string;
  category: string;
  name_FR: string;
  name_NL: string;
};

export const answerGlowDuration = 2000;

const ProductItem = ({
  product,
  selected,
  fixed,
  language,
  onClick,
}: {
  product: any;
  language: string;
  selected?: boolean;
  fixed?: boolean;
  onClick?: (product: any) => void;
}) => {
  const isInDropArea = selected && !fixed;
  return (
    <Stack
      direction="row"
      w="full"
      height="full"
      opacity={fixed ? 0.8 : 1}
      bgColor="white"
      borderRadius="10px"
      borderWidth="3px"
      borderStyle={selected ? 'dashed' : 'solid'}
      borderColor={selected ? 'livelinx.blue400' : 'grey.200'}
      _active={
        !fixed
          ? {
              borderColor: 'livelinx.blue400',
              boxShadow: 'lg',
            }
          : {}
      }
      color="black"
      justifyContent="end"
      justifyItems="center"
      alignItems="center"
      boxShadow={isInDropArea ? 'lg' : 'auto'}
      position="relative"
      onClick={() => onClick?.(product)}
    >
      <Text
        width="80%"
        mr="40%"
        pl="10%"
        size="12px"
        opacity={fixed ? 0.5 : 1}
        textOverflow="ellipsis"
      >
        {language === 'fr' ? product.name_FR : product.name_NL}
      </Text>

      <Image
        position="absolute"
        pointerEvents="none"
        src={`/loreal/skinExpert/productImages/${product.id}.jpg`}
        w="40%"
        h="90%"
        fit="contain"
      />
    </Stack>
    // </Fade>
  );
};

const AddItemBox = (props: {
  id: string;
  selected: string | undefined;
  dropIndex: number;
  product: any;
  language: string;
  onClick: () => void;
}) => {
  return (
    <Box
      width="280px"
      height="130px"
      bgColor={props.selected ? 'livelinx.blue150' : 'livelinx.grey100'}
      color="white"
      textAlign="center"
      borderRadius="10px"
      transform="auto"
      transition="all 0.2s ease-in-out"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={props.onClick}
      cursor="pointer"
    >
      {!props.selected && (
        <VStack color="livelinx.blue200">
          <PlusSquareIcon transition="all 0.2s ease-in-out" fontSize="4xl" />
          <Text>
            {props.language === 'fr'
              ? 'Ajouter un produit'
              : 'Voeg een product toe'}
          </Text>
        </VStack>
      )}
      {props?.product && (
        <ProductItem
          selected
          language={props.language}
          product={props.product}
        />
      )}
    </Box>
  );
};

export const themeIdToProductList: Record<string, Product[]> = {
  'F9-0C-8D': productsThemeA,
  '61-3E-5A': productsThemeB,
  'B7-12-6F': productsThemeC,
  'A8-36-19': productsThemeD,
  '2D-FC-47': productsThemeE,
  '5E-91-AB': productsThemeF,
};

// const filterCategories = (
//   categories: Category[],
//   productsAccordingToTheme: Product[],
//   brand: string
// ) => {
//   return categories.filter((category) => {
//     return productsAccordingToTheme
//       .filter((product) => product.id.includes(brand))
//       .some((product) => product.category == category.id);
//   });
// };

const getUserThemeAccordingToQueryAnswer = (
  query: ReturnType<typeof usePreviousQuestionSlideUserAnswer>
) => {
  if (query.isLoading || !query.data || query.data.length === 0)
    return 'F9-0C-8D';

  // Change above with one of the theme below to test all assets
  // 'F9-0C-8D': productsThemeA,
  // '61-3E-5A': productsThemeB,
  // 'B7-12-6F': productsThemeC,
  // 'A8-36-19': productsThemeD,
  // '2D-FC-47': productsThemeE,
  // '5E-91-AB': productsThemeF,

  return query.data[0].id;
};

export const LorealSkinExpertSlide = (props: Props) => {
  const { meeting, currentCaseId, currentCase } =
    useCurrentMeetingDataContext();
  const { userID } = useUserAuthenticationContext();
  const userThemeAnswerQuery = usePreviousQuestionSlideUserAnswer(
    meeting,
    currentCaseId,
    currentCase,
    '4A-7B-2E',
    userID
  );

  const userThemeAnswer =
    getUserThemeAccordingToQueryAnswer(userThemeAnswerQuery);
  const productsAccordingToTheme: Product[] =
    themeIdToProductList[userThemeAnswer];
  const {
    isOpen: selectionModalIsOpen,
    onClose: closeSelectionModal,
    onOpen: openSelectionModal,
  } = useDisclosure();
  const availableBrands = data.tree.filter((node) =>
    productsAccordingToTheme?.some((product) => product.id.includes(node.brand))
  );

  const [brand, setBrand] = useState<string>(data.tree[0].brand);
  // const [brandCategories, setBrandCategories] = useState<Category[]>([]);
  // const [category, setCategory] = useState<string>('');
  const [products, setProducts] = useState<typeof productsAccordingToTheme>();
  const [selectedItems, setSelectedItems] = useState<(string | undefined)[]>(
    []
  );
  const [currentBox, setCurrentBox] = useState<number>(0);
  const language = currentCase?.language || 'fr';
  const starProductTitle =
    language === 'fr' ? '⭐️ Les Stars' : 'Star producten';
  const starProductInfo =
    language === 'fr'
      ? 'qui jouent le rôle principal au sein de votre thème'
      : 'die de hoofdrol spelen binnen uw thema';
  const coStarProductTitle =
    language === 'fr' ? 'Les Co-Stars' : 'Co-Star producten';
  const coStarProductInfo =
    language === 'fr'
      ? 'qui complètent ou soutiennent vos produits phares'
      : 'die uw star producten aanvullen of ondersteunen';

  const { currentTextAnswers, onTextAnswerChange, isSaving } =
    useQuestionContext();

  // --------------------- handlers ------------------------

  const saveToDatabase = (answers: string[]) => {
    console.log('should save', answers);
    const textAnswers = props.slide.items.filter(isSlideItemTextAnswerItem);
    if (textAnswers.length !== answers.length)
      throw new Error(
        'LorealSkinExpertSlide textAnswers.length !== answers.length'
      );
    onTextAnswerChange(
      answers.map((item, idx) => {
        const answerID = textAnswers[idx].id;
        return {
          answerID,
          text: item,
        };
      })
    );
  };

  const handleProductSelect = (product: Product) => {
    let newItems = [...selectedItems];
    if (newItems.length !== 5) newItems = ['', '', '', '', ''];

    // verify that item is not already in the list
    newItems = newItems.map((item) => {
      return item == product.id ? undefined : item;
    });

    // update product list
    newItems[currentBox] = product.id;
    setSelectedItems(newItems);
    closeSelectionModal();
    saveToDatabase(newItems.map((item) => item || ''));
  };

  const handleBoxClick = (boxId: number) => {
    setCurrentBox(boxId);
    openSelectionModal();
  };

  // --------------------- effects ------------------------

  // useEffect(() => {
  //   const newCategories = filterCategories(
  //     data.tree.find((item) => item.brand == brand)!.categories,
  //     productsAccordingToTheme,
  //     brand
  //   );

  //   // setBrandCategories(newCategories);
  //   setCategory(newCategories[0].id);
  // }, [productsAccordingToTheme, brand]);

  useEffect(() => {
    // const newProducts = productsAccordingToTheme.filter(
    //   (item) => item.category == category && item.id.includes(brand)
    // );
    const newProducts = productsAccordingToTheme.filter((item) =>
      item.id.includes(brand)
    );
    setProducts(newProducts);
  }, [productsAccordingToTheme, brand]);

  useEffect(() => {
    const parsedCurrentTextAnswers = currentTextAnswers.map((item) => {
      return item || '';
    });
    setSelectedItems(parsedCurrentTextAnswers);
  }, [currentTextAnswers]);

  // --------------------- render ------------------------

  return (
    <VStack
      position="absolute"
      w="full"
      justifyContent="center"
      userSelect="none"
      marginTop="5px"
      paddingBottom="250px" // size of dropable area
    >
      <Modal
        isOpen={selectionModalIsOpen}
        isCentered
        onClose={closeSelectionModal}
        size="full"
      >
        <ModalOverlay bgColor="#000000cc" />
        <ModalContent
          bgColor="transparent"
          justifyContent="center"
          alignItems="center"
        >
          <ModalCloseButton color="white" />

          <Stack
            direction="row"
            h="90vh"
            w="90vw"
            borderRadius="10px"
            border="1px solid blue"
            bgColor="white"
          >
            {/* --------------------- BRAND ------------------------ */}
            <VStack
              justify="center"
              px="20px"
              spacing="30px"
              w="20%"
              bgColor="livelinx.blue100"
              boxShadow="lg"
            >
              {data.tree.map((item) => {
                const isBrandAvailable = availableBrands.find(
                  (elem) => elem.brand === item.brand
                );
                return (
                  <Box
                    key={item.brand}
                    w="150px"
                    h="100px"
                    backgroundImage={`/loreal/skinExpert/logos/${item.brand}.jpg`}
                    backgroundPosition="center"
                    backgroundColor="white"
                    backgroundRepeat="no-repeat"
                    backgroundSize="90%"
                    borderRadius="10px"
                    border={brand == item.brand ? '5px solid' : 'inherit'}
                    boxShadow={brand == item.brand ? 'lg' : 'none'}
                    borderColor="livelinx.blue300"
                    onClick={() => {
                      if (isBrandAvailable) setBrand(item.brand);
                    }}
                    opacity={
                      brand == item.brand ? 1 : isBrandAvailable ? 0.8 : 0.3
                    }
                    cursor={isBrandAvailable ? 'pointer' : 'auto'}
                  />
                );
              })}
            </VStack>

            {/* --------------------- CATEGORIES ------------------------ */}

            {/* <VStack justify="space-around" px="20px" bgColor={'blue'}>
                  {brandCategories.map((cat) => (
                    <Button
                      key={cat.id}
                      w="full"
                      backgroundColor={
                        category == cat.id
                          ? 'livelinx.blue200'
                          : 'livelinx.grey'
                      }
                      _hover={{
                        backgroundColor:
                          category == cat.id
                            ? 'livelinx.blue200'
                            : 'livelinx.grey',
                      }}
                      onClick={() => setCategory(cat.id)}
                      opacity={category == cat.id ? 1 : 0.8}
                      color={category == cat.id ? 'white' : 'inherit'}
                    >
                      {cat.name}
                    </Button>
                  ))}
                </VStack>

                <Divider orientation="vertical" /> */}

            {/* --------------------- PRODUCTS ------------------------ */}

            <HStack
              w="full"
              justify="left"
              alignItems="center"
              alignContent="center"
              p="3vw"
              gap="5%"
              wrap="wrap"
            >
              {products?.map((product) => {
                const alreadySelected = selectedItems.includes(product.id);
                return (
                  <Box key={product.id} w="30%" h="150px" cursor="pointer">
                    <ProductItem
                      product={product}
                      fixed={alreadySelected}
                      selected={alreadySelected}
                      language={language}
                      onClick={handleProductSelect}
                    />
                  </Box>
                );
              })}
            </HStack>
          </Stack>
        </ModalContent>
      </Modal>

      {/* --------------------- DROPS AREA ------------------------ */}

      <VStack w="full" justifyContent="space-around" alignItems="center">
        <VStack>
          <Stack direction="column" alignItems="center" gap="-5px">
            <Text fontSize="30px">{starProductTitle}</Text>
            <Text fontSize="18px">{starProductInfo}</Text>
          </Stack>

          <HStack>
            <AddItemBox
              id="drop_area_1"
              language={language}
              dropIndex={0}
              selected={selectedItems[0]}
              product={productsAccordingToTheme?.find(
                (item) => item.id == selectedItems[0]
              )}
              onClick={() => {
                handleBoxClick(0);
              }}
            />
            <AddItemBox
              id="drop_area_2"
              language={language}
              dropIndex={1}
              selected={selectedItems[1]}
              product={productsAccordingToTheme?.find(
                (item) => item.id == selectedItems[1]
              )}
              onClick={() => {
                handleBoxClick(1);
              }}
            />
            <AddItemBox
              id="drop_area_3"
              language={language}
              dropIndex={2}
              selected={selectedItems[2]}
              product={productsAccordingToTheme?.find(
                (item) => item.id == selectedItems[2]
              )}
              onClick={() => {
                handleBoxClick(2);
              }}
            />
          </HStack>
        </VStack>

        <VStack paddingTop="20px">
          <Stack direction="column" alignItems="center" gap="-5px">
            <Text fontSize="30px">{coStarProductTitle}</Text>
            <Text fontSize="18px">{coStarProductInfo}</Text>
          </Stack>

          <HStack>
            <AddItemBox
              id="drop_area_4"
              language={language}
              dropIndex={3}
              selected={selectedItems[3]}
              product={productsAccordingToTheme?.find(
                (item) => item.id == selectedItems[3]
              )}
              onClick={() => {
                handleBoxClick(3);
              }}
            />
            <AddItemBox
              id="drop_area_5"
              language={language}
              dropIndex={4}
              selected={selectedItems[4]}
              product={productsAccordingToTheme?.find(
                (item) => item.id == selectedItems[4]
              )}
              onClick={() => {
                handleBoxClick(4);
              }}
            />
          </HStack>
        </VStack>
      </VStack>
      {/* // To avoid saving issue with delayed return from database, we do not allow to select during a Save process
// TODO: find maybe a better way to do this */}
      {isSaving && (
        <Flex
          // zIndex={1}
          position="fixed"
          left="0"
          top="0"
          bgColor="#ffffff66"
          w="100vw"
          h="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <AppSpinner />
        </Flex>
      )}
    </VStack>
  );
};

export default LorealSkinExpertSlide;
