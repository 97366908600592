import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { ReactNode } from 'react';

export type ModalProps = {
  children: ReactNode;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  modalProps: ModalProps | null;
};

export const CustomModal = ({ isOpen, onClose, modalProps }: Props) => {
  if (!modalProps) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>{modalProps.children}</ModalContent>
    </Modal>
  );
};
