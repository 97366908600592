import { Client, Meeting, Team } from '@/services/API';
import { Avatar, Flex, Text, Tooltip } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { parseDate } from '@/utils/helpers/parseDate';
import dateInRangeFilterFn from '@/ui/TanstackTable/Filter/date/dateInRangeFilterFn';
import AdminManageMeetingMenu from './AdminManageMeetingMenu/AdminManageMeetingMenu';
import LiveMeetingInteractionsMenu from '@/components/LiveMeetingInteractionsMenu/LiveMeetingInteractionsMenu';
import CopyButton from '@/ui/CopyButton/CopyButton';
import { getPreviewUrl } from '@/utils/helpers/getPreviewUrl';
import { getGuestUrl } from '@/utils/helpers/getGuestUrl';
import ClientTag from '@/components/ClientTag/ClientTag';
import { MEETING_STATUS } from '@/features/meeting/helpers/getMeetingStatus';
import { formatMinutesToTime } from '@/utils/helpers/formatMinutesToTime';
import { MeetingStatusTag } from '@/components/MeetingStatusTag/MeetingStatusTag';
import CaseClusterTag from '@/components/CaseClusterTag/CaseClusterTag';

export type AdminMeetingListItemType = Meeting & {
  clients: Client[];
  clientText: string;
  meetingStatus: MEETING_STATUS;
  meetingStatusText: string;
  meetingDurationMinutes: number;
  teams: Team[];
  teamsText: string;
};

const columnHelper = createColumnHelper<AdminMeetingListItemType>();

type Props = {
  nonDeletableMeetings: string[];
  onDupeClick: (meeting: Meeting) => void;
  onEditClick: (meeting: Meeting) => void;
  onUserAccess: (meeting: Meeting) => void;
};

const getColumns = ({
  nonDeletableMeetings,
  onDupeClick,
  onEditClick,
  onUserAccess,
}: Props) => {
  const columns = [
    columnHelper.accessor('eventDate', {
      header: 'Event Date',
      filterFn: dateInRangeFilterFn,
      cell: (props) => <Text>{parseDate(props.getValue() || '')}</Text>,
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: (props) => (
        <Flex direction="column" gap={2}>
          <Tooltip label={props.getValue()}>
            <Text fontSize="lg">{props.getValue()}</Text>
          </Tooltip>
          <Flex w="full" justifyContent="left" gap={2}>
            <AdminManageMeetingMenu
              meeting={props.row.original}
              nonDeletableMeetings={nonDeletableMeetings}
              onDupeMeeting={onDupeClick}
              onEditMeeting={onEditClick}
              onUserAccess={onUserAccess}
            />
            <LiveMeetingInteractionsMenu
              meeting={props.row.original}
              buttonProps={{
                variant: 'primary',
                borderRadius: 4,
              }}
            />
          </Flex>
        </Flex>
      ),
    }),
    columnHelper.accessor('clientText', {
      id: 'clients',
      header: 'Client',
      // filterFn: createArrayFilterFn<Client>('clientName'),
      cell: (props) => {
        const clients = props.row.original.clients;
        return (
          <Flex flexDir="row" gap={2} flexWrap="wrap">
            {clients.map((elem) => (
              <ClientTag key={elem.id} client={elem.clientName} />
            ))}
          </Flex>
        );
      },
    }),
    columnHelper.accessor('meetingStatusText', {
      header: 'Status',
      cell: (props) => <MeetingStatusTag meeting={props.row.original} />,
      minSize: 190,
    }),

    columnHelper.accessor('caseCluster.name', {
      id: 'caseCluster',
      header: 'Case Cluster',
      cell: (props) => <CaseClusterTag name={props.getValue()} />,
    }),

    columnHelper.accessor('id', {
      header: 'ID',
      cell: (props) => <Text>{props.getValue()}</Text>,
    }),

    columnHelper.accessor('meetingDurationMinutes', {
      header: 'Duration',
      cell: (props) => {
        const value = props.getValue();

        if (value === 0) return '--';
        if (value < 0) return 'N/A';

        return (
          <Flex alignItems="center" gap={2}>
            <Text>{formatMinutesToTime(value)}</Text>
          </Flex>
        );
      },
      minSize: 100,
    }),

    columnHelper.accessor('host.email', {
      id: 'host',
      header: 'Host',
      cell: (props) => (
        <Flex gap={2}>
          <Avatar
            name={props.getValue() || 'unknown'}
            color="white"
            size="xs"
          />
          <Text size="xs">{props.getValue()}</Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('teamsText', {
      id: 'teams',
      header: 'Teams',
      // filterFn: createArrayFilterFn<Client>('clientName'),
      cell: (props) => {
        const teams = props.row.original.teams;
        return (
          <Flex flexDir="row" gap={2} flexWrap="wrap">
            {teams.map((elem) => (
              <ClientTag key={elem.id} client={elem.teamName} />
            ))}
          </Flex>
        );
      },
    }),

    columnHelper.accessor('eventLocation', {
      header: 'Event Location',
    }),
    columnHelper.display({
      id: 'guestUrlCode',
      header: 'Guest code',
      cell: (props) => {
        const meetingID = props.row.original.id;
        const code = props.row.original.guestUrlCode;
        if (!code) return null;
        return (
          <Flex alignItems="center" gap={2}>
            <Text>{code}</Text>
            <CopyButton text={getGuestUrl(meetingID, code)} size="xs" />
          </Flex>
        );
      },
    }),
    columnHelper.display({
      id: 'previewCode',
      header: 'Preview code',
      cell: (props) => {
        const meetingID = props.row.original.id;
        const code = props.row.original.previewCode;
        if (!code) return null;
        return (
          <Flex alignItems="center" gap={2}>
            <Text>{code}</Text>
            <CopyButton text={getPreviewUrl(meetingID, code)} size="xs" />
          </Flex>
        );
      },
    }),
    columnHelper.accessor('purchaseOrderNbr', {
      header: 'PO',
    }),
    columnHelper.accessor('meetingType', {
      header: 'Type',
    }),
    columnHelper.accessor('isInTesting', {
      header: 'Sandbox',
      cell: (props) => <Text>{props.getValue() ? 'Yes' : 'No'}</Text>,
    }),
    columnHelper.accessor('isArchived', {
      header: 'Archived',
      cell: (props) => <Text>{props.getValue() ? 'Yes' : 'No'}</Text>,
    }),
    columnHelper.accessor('isDemo', {
      header: 'Demo',
      cell: (props) => <Text>{props.getValue() ? 'Yes' : 'No'}</Text>,
    }),
    // columnHelper.display({
    //   id: 'actions',
    //   header: 'Actions',
    //   cell: (props) => (
    //     <Flex justifyContent="left">
    //       <AdminManageMeetingMenu
    //         meeting={props.row.original}
    //         nonDeletableMeetings={nonDeletableMeetings}
    //         onDupeMeeting={onDupeClick}
    //         onEditMeeting={onEditClick}
    //         onUserAccess={onUserAccess}
    //       />
    //       <LiveMeetingInteractionsMenu
    //         meeting={props.row.original}
    //         buttonProps={{
    //           variant: 'primary',
    //           borderRadius: 4,
    //         }}
    //       />
    //     </Flex>
    //   ),
    //   minSize: 200,
    // }),
  ];

  return columns;
};

export default getColumns;
