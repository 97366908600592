import { pureFetchCaseList } from '@/store/thunk/caseCluster';
import { caseListQueyKeys } from '@/utils/queryKeys';
import { unloadedIPatientCaseSchema } from '@/utils/types/zod/patientCaseSchema';
import { useQuery } from '@tanstack/react-query';

export const useCaseListQuery = () => {
  const query = useQuery({
    queryKey: caseListQueyKeys.list(),
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const responseJson = await pureFetchCaseList();
      for (const unloadedCase of responseJson) {
        unloadedIPatientCaseSchema.parse(unloadedCase);
      }
      return responseJson;
    },
  });
  return query;
};
