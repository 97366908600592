import { Meeting, User, UserClient, UserMeetingAccess } from '@/services/API';

const getAvailableMeetingsByUser = (
  user: User,
  userClients: UserClient[],
  userMeetingAccesses: UserMeetingAccess[],
  meetingList: Meeting[] | undefined
): string[] => {
  let availableMeetings: string[] = [];

  // meetings with useraccess
  const userAccessMeeting = meetingList
    ?.filter((meeting) =>
      userMeetingAccesses.find((access) => access?.meetingID === meeting.id)
    )
    .map((sess) => sess.id);

  //access because part of the team assigned to the meeting
  const isPartOfTeamMeetings = meetingList?.filter((meeting) =>
    user.teamID ? meeting.teamIDs?.includes(user.teamID) : false
  );

  // add all meetings where user is host
  const userMeetings = meetingList
    ?.filter((sess) => sess.hostID === user.id)
    .map((sess) => sess.id);

  // add all meetings with client access
  const clientAccessMeetings = meetingList
    ?.filter((sess) =>
      //where the user's clients are in the meeting's clients
      userClients?.find((client) =>
        sess?.clientIDs?.find(
          (meetingClientID) => meetingClientID === client.clientID
        )
      )
    )
    .map((sess) => sess.id);

  if (userMeetings) availableMeetings = [...availableMeetings, ...userMeetings];
  if (clientAccessMeetings)
    availableMeetings = [...availableMeetings, ...clientAccessMeetings];
  if (userAccessMeeting)
    availableMeetings = [...availableMeetings, ...userAccessMeeting];
  if (isPartOfTeamMeetings)
    availableMeetings = [
      ...availableMeetings,
      ...isPartOfTeamMeetings.map((sess) => sess.id),
    ];

  // return list with no duplicates
  return [...Array.from(new Set(availableMeetings))];
};

const getAvailableUserMeetings = (
  meetingList: Meeting[],
  user: User,
  userClients: UserClient[],
  userMeetingAccesses: UserMeetingAccess[],
  isAdmin: boolean
): Meeting[] => {
  const meetingFiltered = [...meetingList];
  // display always meetings of current user on top
  meetingFiltered
    .sort((meeting) => (meeting.hostID == user.id ? -1 : 1))
    // sort by creation date
    .sort((a, b) => {
      if (!a.createdAt || !b.createdAt) {
        return 0;
      }

      const aDate = new Date(a.createdAt).getTime();
      const bDate = new Date(b.createdAt).getTime();
      return aDate > bDate ? -1 : 1;
    });

  if (isAdmin) return meetingFiltered;
  // if not admin, filter by available meetings
  const availableMeetings = getAvailableMeetingsByUser(
    user,
    userClients,
    userMeetingAccesses,
    meetingFiltered
  );
  return meetingFiltered.filter((meeting) =>
    availableMeetings.includes(meeting.id)
  );
};

export default getAvailableUserMeetings;
