import { Image as ChakraImage } from '@chakra-ui/image';
import { useMemo, useState } from 'react';
import { MotionBox } from '@/ui/Motion/MotionBox';
import Lottie from 'lottie-react';
import animationData from './loadingAnimation.lottie.json';
import { Box, BoxProps, ImageProps } from '@chakra-ui/react';

type Props = {
  src: string;
  alt: string | undefined;
  className?: string;
  imageStartsLoadingInstantly?: boolean;
  fit?: ImageProps['fit'];
  animScale?: number; // optional scale for the loading animation
  onLoadCallback?: () => void;
} & BoxProps;

const MIN_LOADTIME_BEFORE_ANIMATION = 100;
const MIN_ANIMATION_TIME = 500;

export default function ImageWithLoader({
  src,
  alt,
  className,
  fit,
  maxW,
  maxH,
  animScale = 0.5,
  onLoadCallback,
  ...other
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const startTime = useMemo(() => new Date(), [src]);
  //NOTE: we don't want to show the loading animation if the image loads too quickly
  // so we clear this timeout if the image loads before MIN_LOADTIME_BEFORE_ANIMATION
  const startLoadingTimeout = useMemo(() => {
    return setTimeout(() => {
      setLoading(true);
    }, MIN_LOADTIME_BEFORE_ANIMATION);
  }, [src]);

  const onImageLoaded = () => {
    const now = new Date();
    const timeSinceAnimationStart =
      now.getTime() - (startTime.getTime() + MIN_LOADTIME_BEFORE_ANIMATION);

    //NOTE: If it loaded and the loading time was less then the MIN_LOADTIME_BEFORE_ANIMATION then clear the timout
    // because that means we haven't started showing the animation yet
    if (now.getTime() - startTime.getTime() < MIN_LOADTIME_BEFORE_ANIMATION) {
      clearTimeout(startLoadingTimeout);
      //NOTE: it should not be loading right now but just to be sure, set it to false
      setLoading(false);
      onLoadCallback && onLoadCallback();
      return;
    }
    setTimeout(() => {
      clearTimeout(startLoadingTimeout);
      setLoading(false);
    }, MIN_ANIMATION_TIME - timeSinceAnimationStart);
    onLoadCallback && onLoadCallback();
  };

  return (
    <Box userSelect="none" draggable={false} overflow="hidden" {...other}>
      {
        // case when the image is loading
        loading && (
          <Lottie
            animationData={animationData}
            data-test="image-loading-animation"
            loop={true}
            style={{
              width: '100%',
              height: '100%',
              transform: animScale ? `scale(${animScale})` : undefined,
            }}
          />
        )
      }
      <MotionBox
        variants={{
          hidden: { opacity: 0, scale: 0.8 },
          visible: { opacity: 1, scale: 1 },
        }}
        animate={loading ? 'hidden' : 'visible'}
        overflow="hidden"
        width={fit ? '100%' : undefined}
        height={fit ? '100%' : undefined}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <ChakraImage
          className={className}
          src={src}
          alt={alt}
          style={{
            display: loading ? 'none' : 'block',
          }}
          maxWidth={maxW || '100%'}
          maxHeight={maxH || '100%'}
          onLoad={onImageLoaded}
          fit={fit}
          width={fit ? '100%' : undefined}
          height={fit ? '100%' : undefined}
          draggable={false}
        />
      </MotionBox>
    </Box>
  );
}
