import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { Team } from '@/services/API';
import dateInRangeFilterFn from '@/ui/TanstackTable/Filter/date/dateInRangeFilterFn';
import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { parseDate } from '@/utils/helpers/parseDate';
import { DeleteOutlined } from '@ant-design/icons';
import { FiEdit, FiMoreVertical } from 'react-icons/fi';

const columnHelper = createColumnHelper<Team>();

type Args = {
  t: TFunction<'translation', undefined, 'translation'>;
  onEditTeamClick: (elem: Team) => void;
  onDeleteTeamClick: (elem: Team) => void;
};

const getColumns = ({ t, onDeleteTeamClick, onEditTeamClick }: Args) => {
  const columns = [
    columnHelper.accessor('teamName', {
      header: 'Team Name',
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      filterFn: dateInRangeFilterFn,
      cell: (props) => <Text>{parseDate(props.getValue() || '')}</Text>,
    }),
    // columnHelper.display({
    //   id: 'actions',
    //   header: 'Actions',
    //   cell: (props) => {
    //     const item = props.row.original;
    //     return (
    //       <PopConfirm
    //         title={
    //           <div>
    //             {t('admin.teams.delete.confirmText')}
    //             <br />
    //             {t('meeting.header.confirmRestart')}
    //           </div>
    //         }
    //         onConfirm={() => onDeleteTeamClick(item)}
    //         okText={t('common.yes') as string}
    //         cancelText={t('common.no') as string}
    //       >
    //         <Button
    //           variant="danger"
    //           key="delete"
    //           size="sm"
    //           rightIcon={<DeleteOutlined />}
    //         >
    //           {t('common.delete')}
    //         </Button>
    //       </PopConfirm>
    //     );
    //   },
    // }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: (props) => {
        const data = props.row.original;
        return (
          <HStack>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical />}
                variant="outline"
                size="xs"
                border="none"
              />
              <MenuList>
                <MenuItem
                  icon={<FiEdit />}
                  onClick={() => {
                    onEditTeamClick(data);
                  }}
                >
                  {' '}
                  {t('common.edit')}
                </MenuItem>
                <MenuItem
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    onDeleteTeamClick(data);
                  }}
                >
                  {' '}
                  {t('common.delete')}
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        );
      },
    }),
  ];

  return columns;
};

export default getColumns;
