import { Flex, Icon, Link, FlexProps } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { IconType } from 'react-icons';

interface NavItemProps extends FlexProps {
  isActive: boolean;
  icon: IconType;
  children: string;
  path: string;
}
const NavItem = ({ icon, isActive, children, path, ...rest }: NavItemProps) => {
  return (
    <Link
      as={NavLink}
      to={path}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      _activeLink={{
        backgroundColor: '#0BC5EA',
        color: 'white',
      }}
      color="gray.600"
    >
      <Flex
        align="center"
        p={3}
        mx={3}
        my={1}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        bg={isActive ? 'cyan.400' : 'transparent'}
        {...rest}
      >
        {icon && <Icon mr={2} fontSize="16" as={icon} />}
        {children}
      </Flex>
    </Link>
  );
};
export default NavItem;
