import {
  UpdateTeamMutation,
  UpdateTeamMutationVariables,
} from '@/services/API';
import { updateTeam } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export const mutationUpdateTeam = async (
  variables: UpdateTeamMutationVariables
) => {
  const result = await callGraphQLApi<GraphQLResult<UpdateTeamMutation>>(
    updateTeam,
    variables
  );
  return result;
};
