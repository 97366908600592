import { Team } from '@/services/API';
import createTanstackTableDataStore from '@/ui/TanstackTable/createTanstackTableDataStore';

const useAdminTeamTableStore = createTanstackTableDataStore<Team>({
  storeName: 'admin-team-table-store',
  defaultColumnSizing: {},
  defaultColumnVisibility: {},
});

export default useAdminTeamTableStore;
