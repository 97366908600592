import {
  CreateTeamMutation,
  CreateTeamMutationVariables,
} from '@/services/API';
import { createTeam } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export const mutationCreateTeam = async (
  variables: CreateTeamMutationVariables
) => {
  const result = await callGraphQLApi<GraphQLResult<CreateTeamMutation>>(
    createTeam,
    variables
  );
  return result;
};
