import { Client, ListClientsQuery } from '@/services/API';
import { listClients } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { clientsQueryKeys } from '@/utils/queryKeys';
import { GraphQLResult } from '@aws-amplify/api';
import { useQuery } from '@tanstack/react-query';

export const useAllClientListQuery = () => {
  const query = useQuery({
    queryKey: clientsQueryKeys.list(),
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await callGraphQLApi<GraphQLResult<ListClientsQuery>>(
        listClients,
        {}
      );
      const items = response?.data?.listClients?.items as Client[];
      return items;
    },
  });
  return query;
};
