import { queryClient } from '@/index';
import {
  OnCreateTeamSubscription,
  OnDeleteTeamSubscription,
  OnUpdateTeamSubscription,
} from '@/services/API';
import {
  onCreateTeam,
  onDeleteTeam,
  onUpdateTeam,
} from '@/services/graphql/subscriptions';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';
import { teamsQueryKeys } from '@/utils/queryKeys';
import { useMemo } from 'react';

export const useAllTeamsSubscription = () => {
  const subscriptions = useMemo(() => {
    return [
      {
        query: onCreateTeam,
        variables: {},
        callback: (_result: OnCreateTeamSubscription) => {
          queryClient.invalidateQueries(teamsQueryKeys.list());
        },
      },
      {
        query: onUpdateTeam,
        variables: {},
        callback: (_result: OnUpdateTeamSubscription) => {
          queryClient.invalidateQueries(teamsQueryKeys.list());
        },
      },
      {
        query: onDeleteTeam,
        variables: {},
        callback: (_result: OnDeleteTeamSubscription) => {
          queryClient.invalidateQueries(teamsQueryKeys.list());
        },
      },
    ];
  }, []);

  useNewSubscriptions(subscriptions, 'useAllTeamsSubscription');

  return;
};
