import {
  DeleteTeamMutation,
  DeleteTeamMutationVariables,
} from '@/services/API';
import { deleteTeam } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export const mutationDeleteTeam = async (teamId: string) => {
  const variables: DeleteTeamMutationVariables = {
    input: {
      id: teamId,
    },
  };
  const result = await callGraphQLApi<GraphQLResult<DeleteTeamMutation>>(
    deleteTeam,
    variables
  );
  return result;
};
