import {
  UpdateCaseClusterMutation,
  UpdateCaseClusterMutationVariables,
} from '@/services/API';
import { updateCaseCluster } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export const mutationUpdateCaseCluster = async (
  variables: UpdateCaseClusterMutationVariables
) => {
  await callGraphQLApi<GraphQLResult<UpdateCaseClusterMutation>>(
    updateCaseCluster,
    variables
  );
};
