import { mutationCreateTeam } from '@/features/teams/api/mutationCreateTeam';
import { Team, User } from '@/services/API';
import { useUserList } from '@/services/hooks/useUserList';
import { AppSpinner } from '@/ui/AppSpinner';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectableUserList } from '../SelectableUserList/SelectableUserList';
import { useCallback, useMemo, useState } from 'react';
import { isTruthy } from '@/utils/types/type-validation/generic/isTruthy';
import { mutationUpdateUserTeam } from '@/features/teams/api/mutationAddUserToTeam';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { SearchIcon } from '@chakra-ui/icons';
import { queryClient } from '@/index';
import { teamsQueryKeys } from '@/utils/queryKeys';
import { isQueryFinishedLoading } from '@/utils/isQueryFinishedLoading';
import { mutationUpdateTeam } from '@/features/teams/api/mutationUpdateTeam';

type Props = {
  data: Team | null;
  closeForm: () => void;
};

type FormData = {
  teamName: string;
};

export default function CreateTeamForm({ data, closeForm }: Props) {
  const { t } = useTranslation();
  const userListQuery = useUserList();
  const isEdit = !!data;
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const currentMemberIds =
    data?.members?.items.filter(isTruthy).map((elem) => elem.id) || [];
  const [currentlySelectedUserIds, setCurrentlySelectedUserIds] =
    useState<string[]>(currentMemberIds);
  const toast = useToast();

  const onSubmit = async (formData: FormData) => {
    if (isEdit) {
      await mutationUpdateTeam({
        input: {
          id: data.id,
          teamName: formData.teamName,
        },
      });
      const toRemove = currentMemberIds.filter(
        (elem) => currentlySelectedUserIds.includes(elem) === false
      );
      const toAdd = currentlySelectedUserIds.filter(
        (elem) => currentMemberIds.includes(elem) === false
      );
      const toRemovePromises = toRemove.map((userId) => {
        return mutationUpdateUserTeam(null, userId);
      });
      const toAddPromises = toAdd.map((userId) => {
        return mutationUpdateUserTeam(data.id, userId);
      });
      await Promise.all([...toRemovePromises, ...toAddPromises]);
    } else {
      const result = await mutationCreateTeam({
        input: {
          teamName: formData.teamName,
        },
      });
      const teamId = result.data?.createTeam?.id;

      if (!teamId) {
        captureSentry({
          title: 'Create Team, teamId is null',
          detail: {
            teamId,
            result,
          },
        });
        return;
      }
      //NOTE: add all the users to the team
      const promises = currentlySelectedUserIds.map((userId) => {
        return mutationUpdateUserTeam(teamId, userId);
      });
      await Promise.all(promises);
    }
    closeForm();
    //NOTE: We need to refetch this in order to get the updated user team data
    queryClient.invalidateQueries(teamsQueryKeys.list());
    toast({
      status: 'success',
      title: t('common.success'),
      position: 'top',
    });
  };

  const handleSelect = useCallback(
    (user: User, newState: boolean) => {
      if (newState) {
        setCurrentlySelectedUserIds((prev) => [...prev, user.id]);
      } else {
        setCurrentlySelectedUserIds((prev) =>
          prev.filter((elem) => elem !== user.id)
        );
      }
    },
    [currentlySelectedUserIds]
  );

  const filteredUserList = useMemo(() => {
    if (!userListQuery.data) return [];
    return userListQuery.data.filter((elem) => {
      if (elem.isMock || elem.isTemporary) return false;

      return elem.email.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [searchQuery, userListQuery.data]);

  if (isQueryFinishedLoading(userListQuery) === false) {
    return <AppSpinner />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={isSubmitting}>
        <VStack gap="10px" pb="10px">
          <FormControl isRequired isInvalid={Boolean(errors.teamName)}>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.teams.form.teamName.label')}
            </FormLabel>
            <Input
              type="text"
              {...register('teamName', { required: true })}
              placeholder="Kit 01"
              defaultValue={(data && data.teamName) || ''}
            />

            {errors.teamName && (
              <FormHelperText color="red">
                {t('admin.teams.form.teamName.error')}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.teams.form.members.label')}
            </FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={SearchIcon} color="gray" />
              </InputLeftElement>
              <Input
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
              />
            </InputGroup>
            <Box justifyContent="left" w="full" ml={2} mt={4}>
              <SelectableUserList
                userList={filteredUserList}
                onSelectionChangeUser={handleSelect}
                selectedUsersIds={currentlySelectedUserIds}
                // NOTE: can not add a user to a team if they are already in a team
                disabledUserList={filteredUserList
                  .filter((elem) => !!elem.teamID && elem.teamID !== data?.id)
                  .map((elem) => elem.id)}
              />
            </Box>
          </FormControl>

          <Spacer h="5px" />

          <Button
            type="submit"
            p="2"
            width="full"
            background="blue.400"
            color="white"
            variant="solid"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {isEdit ? t('common.update') : t('common.create')}
          </Button>
        </VStack>
      </fieldset>
    </form>
  );
}
