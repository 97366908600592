import { ReactNode } from 'react';
import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
} from '@chakra-ui/react';
import { FiUsers, FiLayers, FiMonitor, FiFile } from 'react-icons/fi';
import { MdGroups } from 'react-icons/md';
import { IconType } from 'react-icons';
import { ROUTES } from '../../../../routes/Routes';
import MobileNav from './MobileNav';
import NavItem from './NavItem';
import { useLocation } from 'react-router-dom';
import { SiClickup } from 'react-icons/si';
import { CiMoneyBill } from 'react-icons/ci';
interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Clients', path: ROUTES.ADMIN_CLIENTS, icon: MdGroups },
  { name: 'Teams', path: ROUTES.ADMIN_TEAMS, icon: MdGroups },
  { name: 'Users', path: ROUTES.ADMIN_USER_LIST, icon: FiUsers },
  { name: 'Meetings', path: ROUTES.ADMIN_MEETING_LIST, icon: FiLayers },
  { name: 'Cases', path: ROUTES.ADMIN_CASE_LIST, icon: FiFile },
  {
    name: 'CaseClusters',
    path: ROUTES.ADMIN_CASE_CLUSTER_LIST,
    icon: FiMonitor,
  },
  {
    name: 'Clickup',
    path: ROUTES.ADMIN_CLICKUP,
    icon: SiClickup,
  },
  {
    name: 'Sales',
    path: ROUTES.ADMIN_SALES,
    icon: CiMoneyBill,
  },
];

const leftNavWidth = 44;

export default function Sidebar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box h="100%">
      {/* // --------------------- DESKTOP ------------------------ */}
      <SidebarContent onClose={() => onClose} />

      {/* // --------------------- MOBILE (nav + drawer) ------------------------*/}
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />

      {/* // --------------------- RIGHT AREA ------------------------*/}
      <Box
        ml={{ base: 0, md: leftNavWidth }}
        p="4"
        overflow="auto"
        bg={useColorModeValue('gray.100', 'gray.900')}
        minH="100%" // force background to take full page
        h="100%"
      >
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { pathname } = useLocation();
  const activeSection = pathname;
  console.log('activeSection:', activeSection);
  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: leftNavWidth }}
      pos="fixed"
      h="full"
      marginTop="1px"
      display={{ base: 'none', md: 'block' }}
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          isActive={link.path === activeSection}
          icon={link.icon}
          path={link.path}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};
