import {
  CreateCaseClusterMutation,
  CreateCaseClusterMutationVariables,
} from '@/services/API';
import { createCaseCluster } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export const mutationCreateCaseCluster = async (
  variables: CreateCaseClusterMutationVariables
) => {
  await callGraphQLApi<GraphQLResult<CreateCaseClusterMutation>>(
    createCaseCluster,
    variables
  );
};
