import useCaseList from '@/features/caseCluster/hooks/useCaseList';
import CaseListView from './CaseListView';
import { PageLoading } from '@/ui/PageLoading';
import useCaseContent from '@/features/caseCluster/hooks/useCaseClusterContent';
import { useMemo } from 'react';
import { AdminCaseListRowType } from './columns';
import { UnloadedIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import { useAllClientListQuery } from '@/features/clients/hooks/useAllClientListQuery';
import { isQueryFinishedLoading } from '@/utils/isQueryFinishedLoading';
import useCaseClusterList from '@/features/caseCluster/hooks/useCaseClusterList';

export default function CaseListClient() {
  const caseClusterList = useCaseClusterList();
  const caseList = useCaseList();
  const clientList = useAllClientListQuery();
  const caseIds = useMemo(() => {
    if (!caseList) return [];
    return caseList.map((el) => el.id);
  }, [caseList]);
  const caseContents = useCaseContent(caseIds);

  const data = useMemo(() => {
    if (!caseList || caseList.length === 0) return [];
    if (caseContents.isCaseLoading) return [];
    if (!clientList.data) return [];
    if (!caseClusterList) return [];

    return Object.keys(caseContents.caseMap).reduce((acc, curr) => {
      const unloadedCase: UnloadedIPatientCase = caseList.find(
        (el) => el.id === curr
      )!;
      const caseData = caseContents.caseMap[curr];
      const matchedDbClient = clientList.data.find(
        (elem) => elem.clientName === unloadedCase.clientName
      );
      const caseClusterNames = caseClusterList
        .filter((el) => el.cases.includes(curr))
        .map((el) => el.name);

      if (!matchedDbClient) {
        console.warn('DB Client not found for case', unloadedCase.clientName);
      }

      acc.push({
        ...unloadedCase,
        ...caseData,
        numberOfSlides: caseData.slides.length,
        clientID: matchedDbClient?.id || null,
        caseClusterNames,
      });
      return acc;
    }, [] as AdminCaseListRowType[]);
  }, [caseContents, caseIds]);

  if (!caseList || !isQueryFinishedLoading(clientList)) {
    return <PageLoading />;
  }
  return <CaseListView data={data} unloadedCases={caseList} />;
}
