import { User } from '@/services/API';
import { Checkbox, HStack, Text } from '@chakra-ui/react';

type Props = {
  userList: User[];
  disabledUserList?: string[];
  selectedUsersIds: string[];
  onSelectionChangeUser: (user: User, newState: boolean) => void;
};

export const SelectableUserList = ({
  userList,
  selectedUsersIds,
  onSelectionChangeUser,
  disabledUserList,
}: Props) => {
  return (
    <>
      {userList.map((item) => {
        const isChecked = selectedUsersIds.includes(item.id);
        const isDisabled = disabledUserList?.includes(item.id);
        return (
          <HStack alignItems="center" justifyContent="left" key={item.id}>
            <Checkbox
              size="lg"
              isChecked={isChecked}
              onChange={(e) => onSelectionChangeUser(item, e.target.checked)}
              isDisabled={isDisabled}
            />
            <Text userSelect="none" opacity={isDisabled ? 0.7 : 1}>
              {item.email}{' '}
            </Text>
          </HStack>
        );
      })}
    </>
  );
};
