import { useUserList } from '@/services/hooks/useUserList';
import { AdminUserListView } from './AdminUserListView';
import { MOCK_USER_PREFIX } from '@/features/meeting-stats/mocking-tool/mockUser/users.create';
import useUserListClients from '@/services/hooks/useUserListClients';
import { useMemo } from 'react';
import { PageLoading } from '@/ui/PageLoading';

export const AdminUserListClient = () => {
  const { userListToClientNames } = useUserListClients();
  const userList = useUserList();

  const parsedUserList = useMemo(() => {
    if (!userList.data) {
      return [];
    }
    return userList.data
      .filter((el) => !el.email.startsWith(MOCK_USER_PREFIX))
      .map((el) => {
        return {
          ...el,
          last_seen: el.last_seen ?? el.createdAt,
        };
      });
  }, [userList]);

  if (userList.isLoading || !userList.data) {
    return <PageLoading />;
  }

  return (
    <AdminUserListView
      userList={parsedUserList}
      userListToClientNames={userListToClientNames}
    />
  );
};
