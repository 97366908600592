import { useQuery } from '@tanstack/react-query';
import { listTeamsQuery } from '../api/listTeamsQuery';
import { teamsQueryKeys } from '@/utils/queryKeys';

export const useAllTeamsList = () => {
  const query = useQuery({
    queryKey: teamsQueryKeys.list(),
    refetchOnWindowFocus: false,
    enabled: true,
    queryFn: async () => {
      const data = await listTeamsQuery({});
      return data;
    },
  });

  return query;
};
