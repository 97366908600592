import useNetworkConnection from '@/utils/helpers/hooks/useNetwork';
import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { keyframes } from '@chakra-ui/react';

type Props = {
  isVisible: boolean;
  isEnabled: boolean;
  onConfirmCallback: () => void;
  amountSelectedAnswers: number;
  maxAnswers: number;
} & ButtonProps;

const buttonGlowAnimation = keyframes`
  0% {
    background-color: #ff7f68ab;
    box-shadow: 0 0 3px #ff7f68ab;
  }
  50% {
    background-color: #ff7f68;
    box-shadow: 0 0 25px #ff7f68;
  }
  100% {
    background-color: #ff7f68cc;
    box-shadow: 0 0 3px #ff7f68cc;
  }
`;

function ConfirmButton({
  isEnabled,
  isVisible,
  onConfirmCallback,
  amountSelectedAnswers,
  maxAnswers,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const { online } = useNetworkConnection();
  return (
    <Button
      size="sm"
      color="#FFF"
      background="primary"
      fontSize="1em"
      borderRadius="25px"
      border="none"
      visibility={isVisible ? 'visible' : 'hidden'}
      transform={isVisible ? 'scale(1)' : 'scale(0.8)'}
      transition=" all 0.2s ease-out"
      animation={`${buttonGlowAnimation} 1500ms infinite`}
      onClick={() => onConfirmCallback()}
      className={`submitButton animateButton${
        isVisible ? '' : ' buttonHidden'
      }`}
      isDisabled={!online || !isEnabled}
      padding="25px"
      data-test="submit-choice-button"
      {...rest}
    >
      {t('home.slide.cards.submitChoice.button', {
        amt: amountSelectedAnswers,
        total: maxAnswers,
      })}
    </Button>
  );
}

export default ConfirmButton;
