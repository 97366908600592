import { Spinner } from '@chakra-ui/react';

type Props = {
  size?: string;
};
export const AppSpinner = ({ size }: Props) => {
  return (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size={size || 'xl'}
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin="auto"
      id="app-spinner"
    />
  );
};
