import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useStandaloneModal } from '../StandaloneModal/StandaloneModalProvider';

type Props = {
  title: string | ReactNode;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  disclosure?: ReturnType<typeof useDisclosure>;
};

export default function ConfirmModal({
  title,
  onConfirm,
  okText,
  cancelText,
  disclosure,
}: Props) {
  const localDisclosure = useDisclosure();
  const isOpen = disclosure?.isOpen || localDisclosure.isOpen;
  const onClose = disclosure?.onClose || localDisclosure.onClose;

  return (
    <Modal
      isCentered
      scrollBehavior="inside"
      motionPreset="scale"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay bgColor="blackAlpha.800" />
      <ModalContent borderRadius={4} boxShadow="dark-lg">
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>{title}</ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button variant="outline" onClick={onClose}>
              {cancelText ? cancelText : 'Cancel'}
            </Button>
            <Button
              variant="blue"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              {okText ? okText : 'Ok'}
            </Button>
          </ButtonGroup>
        </ModalFooter>
        <ModalCloseButton color="black" />
      </ModalContent>
    </Modal>
  );
}

export function useConfirmModal() {
  const { openModal, closeModal } = useStandaloneModal();

  return {
    confirm: ({ title, okText, cancelText, onConfirm }: Props) => {
      openModal({
        children: (
          <>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalBody>{title}</ModalBody>
            <ModalFooter>
              <ButtonGroup size="sm">
                <Button variant="outline" onClick={closeModal}>
                  {cancelText ? cancelText : 'Cancel'}
                </Button>
                <Button
                  variant="blue"
                  onClick={() => {
                    onConfirm();
                    closeModal();
                  }}
                >
                  {okText ? okText : 'Ok'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
            <ModalCloseButton color="black" />
          </>
        ),
      });
    },
  };
}
