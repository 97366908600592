import { useQuery, UseQueryResult } from '@tanstack/react-query';
import queryUserList from '../graphql/queryUserList';
import { User } from '../API';
import { usersQueryKeys } from '@/utils/queryKeys';

/**
 * use global user list (should be only used in admin actually, or must be filtered somehow)
 */
export function useUserList() {
  const query: UseQueryResult<User[], unknown> = useQuery({
    queryKey: usersQueryKeys.list(),
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const result = await queryUserList();
      const items = result.data?.listUsers?.items;
      return items || [];
    },
  });

  return query;
}
