import { createColumnHelper } from '@tanstack/react-table';
import {
  PatientCase,
  UnloadedIPatientCase,
} from '@/utils/types/zod/patientCaseSchema';
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FiEdit, FiEye, FiMoreVertical } from 'react-icons/fi';
import { MdOutlineAutorenew } from 'react-icons/md';
import { TFunction } from 'i18next';
import ClientTag from '@/components/ClientTag/ClientTag';
import CaseClusterTag from '@/components/CaseClusterTag/CaseClusterTag';

export type AdminCaseListRowType = PatientCase &
  UnloadedIPatientCase & {
    numberOfSlides: number;
    clientID: string | null;
    caseClusterNames: string[];
  };

const columnHelper = createColumnHelper<AdminCaseListRowType>();

type Args = {
  t: TFunction<'translation', undefined, 'translation'>;
  onViewCaseClick: (data: PatientCase) => void;
  onPreviewLinkClick: (data: PatientCase) => void;
  onEditCaseClick: (data: PatientCase) => void;
};

const getColumns = ({
  t,
  onViewCaseClick,
  onPreviewLinkClick,
  onEditCaseClick,
}: Args) => {
  const columns = [
    // columnHelper.accessor('id', {
    //   header: 'ID',
    //   cell: (props) => props.row.original.id,
    // }),
    columnHelper.accessor('displayName', {
      header: 'Name',
      cell: (props) => {
        const { name, displayName, id } = props.row.original;
        return (
          <Tooltip label={displayName}>
            <div>
              <Text>{name}</Text>
              <Text fontSize={12} fontStyle="italic">
                {displayName}
              </Text>
              <Text fontSize={12} fontStyle="italic">
                ID: {id}
              </Text>
            </div>
          </Tooltip>
        );
      },
    }),
    columnHelper.accessor('clientName', {
      header: 'Client',
      cell: (props) => {
        const hasMatchedDBClient = !!props.row.original.clientID;
        if (!hasMatchedDBClient) {
          return (
            <Tag ml={2} colorScheme="red">
              NOT FOUND
            </Tag>
          );
        }
        return <ClientTag client={props.getValue()} />;
      },
    }),
    columnHelper.accessor('creationDate', {
      header: 'creationDate',
    }),

    columnHelper.accessor('numberOfSlides', {
      header: '# of Slides',
    }),
    columnHelper.accessor('purchaseOrder', {
      header: 'PO',
    }),

    columnHelper.accessor('caseClusterNames', {
      header: 'Clusters',
      cell: (props) => {
        const elems = props.getValue();
        return (
          <Flex flexDir="row" gap={2} flexWrap="wrap">
            {elems.map((elem) => (
              <CaseClusterTag key={elem} name={elem} />
            ))}
          </Flex>
        );
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: (props) => {
        const data = props.row.original;
        return (
          <HStack>
            <Button onClick={() => onViewCaseClick(data)} variant="secondary">
              <FiEye />
              <Text ml="5px">{t('admin.cases.table.viewCase.button')}</Text>
            </Button>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical />}
                variant="outline"
              />
              <MenuList>
                <MenuItem
                  icon={<MdOutlineAutorenew />}
                  onClick={() => {
                    onPreviewLinkClick(data);
                  }}
                >
                  {t('admin.cases.table.actions.generatePreview.button')}
                </MenuItem>
                <MenuItem
                  icon={<FiEdit />}
                  onClick={() => {
                    onEditCaseClick(data);
                  }}
                >
                  {' '}
                  {t('common.edit')}
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        );
      },
      minSize: 200,
    }),
  ];

  return columns;
};

export default getColumns;
