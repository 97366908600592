import { useState } from 'react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { AssetType } from '@/utils/types/enums';
import { Text, Flex, IconButton, HStack, VStack, Box } from '@chakra-ui/react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Asset } from '@/utils/types/zod/assetSchema';
import ImageWithLoader from '../ImageWithLoader/ImageWithLoader';
import CarretRight from '@/assets/images/CaretRight.svg';
import CarretLeft from '@/assets/images/CaretLeft.svg';
import AssetListPreview from './AssetListPreview';
import { IsMobile } from '@/utils/helpers/browser.helper';
import APP_CONSTANTS from '@/utils/constants/app.constants';

type Props = {
  assetList: Asset[]; // list of availabla assets
  index?: number; // optional current index in list of assets
  onModalClose: () => void;
  onAssetChange?: (oldAsset: Asset, newAsset: Asset) => void;
};

const AssetModal = ({
  assetList,
  index = 0,
  onModalClose,
  onAssetChange,
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(index);
  const [assetScale, setAssetScale] = useState(1);
  const hasMoreThenOneAsset = assetList && assetList.length > 1;
  const currentAsset: Asset = assetList[currentIndex];
  const [isLoading, setIsLoading] = useState(true);

  if (!currentAsset) {
    alert(`No asset found:${currentIndex} in ${JSON.stringify(assetList)}`);
  }

  // handlers
  const nextAsset = () => {
    changeIndex((currentIndex + assetList.length + 1) % assetList.length);
  };
  const previousAsset = () => {
    changeIndex((currentIndex + assetList.length - 1) % assetList.length);
  };
  const changeIndex = (newIndex: number) => {
    if (currentIndex !== newIndex) {
      setCurrentIndex(newIndex);
      onAssetChange?.(assetList[currentIndex], assetList[newIndex]);
    }
  };

  return (
    <Flex
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex={10}
      justifyContent="center"
      alignItems="center"
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: APP_CONSTANTS.SHOW_ANIMATIONS
          ? { duration: 0.2, ease: 'circOut' }
          : { duration: 0 },
      }}
    >
      {/* overlay */}
      <Flex
        width="100%"
        height="100%"
        position="absolute"
        bg="black"
        opacity={0.94}
        top={0}
        left={0}
        zIndex={0}
        onClick={onModalClose}
      />

      {/* content */}
      <VStack
        width="full"
        height="full"
        as={motion.div}
        initial={{ opacity: 0, scale: 0.7 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: APP_CONSTANTS.SHOW_ANIMATIONS
            ? { duration: 0.3, delay: 0.2, ease: 'easeOut' }
            : { duration: 0 },
        }}
      >
        <HStack
          justifyContent={hasMoreThenOneAsset ? 'space-between' : 'center'}
          width="full"
          h="full"
        >
          {hasMoreThenOneAsset && (
            <IconButton
              fontSize="32px"
              boxSize="60px"
              marginX={8}
              className="previous-button"
              variant="meetingStats"
              aria-label="previous"
              icon={<CarretLeft />}
              onClick={previousAsset}
              as={motion.div}
              initial={{ opacity: 0, translateX: -200 }}
              animate={{
                opacity: 1,
                translateX: 0,
                transition: APP_CONSTANTS.SHOW_ANIMATIONS
                  ? { duration: 0.5, delay: 0.4, ease: 'easeOut' }
                  : { duration: 0 },
              }}
            />
          )}
          <VStack width="min-content" minW="300px" minH="300px">
            {!isLoading && (
              <Text
                alignSelf="start"
                color="white"
                noOfLines={2}
                minW="0"
                flexShrink={1}
                whiteSpace="normal"
                wordBreak="break-word"
                overflowWrap="break-word"
                zIndex={0}
                data-test="asset-modal-title"
              >
                {currentAsset.title}
              </Text>
            )}
            <Flex
              flexDir="column"
              bg="transparent"
              borderRadius="10px"
              overflow="hidden"
            >
              {currentAsset && currentAsset.type === AssetType.IMAGE && (
                <TransformWrapper
                  key={currentAsset.id} // Hack: force a rerender otherwise the onPanningStop function is not changed
                  disabled={!IsMobile()}
                  centerZoomedOut={true}
                  limitToBounds={assetScale > 0.9}
                  disablePadding={true}
                  velocityAnimation={{ equalToMove: true }}
                  onZoom={(ref) => setAssetScale(ref.state.scale)}
                  onPanningStop={(touch) => {
                    if (!touch.instance.lastMousePosition) {
                      return;
                    }
                    const offsetX = touch.instance.lastMousePosition.x;
                    const offsetY = Math.abs(
                      touch.instance.lastMousePosition.y
                    );
                    if (touch.state.scale === 1 && assetList.length > 1) {
                      if (offsetY > 100) {
                        return;
                      }
                      if (offsetX > 60) {
                        previousAsset();
                      } else if (offsetX < -60) {
                        nextAsset();
                      }
                    }
                  }}
                >
                  <TransformComponent
                    wrapperStyle={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ImageWithLoader
                      alt={currentAsset.title}
                      maxW="80vw"
                      maxH={hasMoreThenOneAsset ? '70vh' : '80vh'}
                      src={currentAsset.url || ''}
                      onLoadCallback={() => setIsLoading(false)}
                    />
                  </TransformComponent>
                </TransformWrapper>
              )}

              {currentAsset && currentAsset.type === AssetType.VIDEO && (
                <ReactPlayer
                  controls={true}
                  url={currentAsset.url}
                  playing={true}
                  loop
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                      },
                    },
                  }}
                />
              )}
            </Flex>
          </VStack>

          {/* next button */}
          {hasMoreThenOneAsset && (
            <IconButton
              fontSize="30px"
              boxSize="60px"
              marginX={8}
              className="next-button"
              variant="meetingStats"
              aria-label="next"
              icon={<CarretRight />}
              onClick={nextAsset}
              as={motion.div}
              initial={{ opacity: 0, translateX: 200 }}
              animate={{
                opacity: 1,
                translateX: 0,
                transition: APP_CONSTANTS.SHOW_ANIMATIONS
                  ? { duration: 0.5, delay: 0.4, ease: 'easeOut' }
                  : { duration: 0 },
              }}
            />
          )}
        </HStack>

        {
          /* asset list preview */
          hasMoreThenOneAsset && (
            <Box
              as={motion.div}
              initial={{ opacity: 0, translateY: 300 }}
              animate={{
                opacity: 1,
                translateY: 0,
                transition: APP_CONSTANTS.SHOW_ANIMATIONS
                  ? { duration: 0.5, delay: 0.4, ease: 'easeOut' }
                  : { duration: 0 },
              }}
            >
              <AssetListPreview
                assetList={assetList}
                currentIndex={currentIndex}
                onChangeIndex={changeIndex}
              />
            </Box>
          )
        }
      </VStack>

      {/* close button */}
      <IconButton
        position="absolute"
        top={0}
        right={0}
        role="button"
        boxSize="80px"
        fontSize="40px"
        borderRadius="full"
        color="white"
        aria-label="close"
        variant="unstyled"
        icon={<CloseCircleOutlined />}
        onClick={onModalClose}
        _focusVisible={{ border: 'none' }}
      />

      {/* asset id */}
      <Text
        position="absolute"
        bottom={1}
        right={2}
        color="gray.600"
        fontSize="xs"
      >
        {currentAsset.id}
      </Text>
    </Flex>
  );
};

export default AssetModal;
